import { BaseControl, BaseProps } from '@controls/Base';
import useControl from '@hooks/useControl';
import tw from 'twin.macro';
import Control from './Control';

const Container = tw.div`overflow-hidden select-none flex items-start justify-start border border-black`;

interface Props {
    controlId: number;
}

function isTextBox(control: BaseControl<BaseProps> | undefined): control is Control {
    return !!control && control.type === 'textbox';
}

function Button({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isTextBox(control)) throw new Error('Bad render call');
    let [width, height]: [number | undefined, number | undefined] = [undefined, undefined];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.height + 2;
    }

    return (
        <Container
            style={{
                width,
                height: height ? height - 2 : undefined,
            }}
        />
    );
}

export default Button;
