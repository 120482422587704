import { MouseEvent, ReactElement, useCallback } from 'react';
import tw from 'twin.macro';

const Presenter = tw.div`bg-transparent z-10 fixed top-0 left-0 w-screen h-screen`;
const Container = tw.div`bg-white border-gray-200 flex flex-col space-y-1 absolute p-2 z-20 w-40`;
const Header = tw.div`text-gray-500 text-xs`;

interface Props {
    children: ReactElement | ReactElement[];
    header?: string | undefined;
    isOpen: boolean;
    top: number;
    left: number;
    onClose: () => void;
}

function PopupMenu({ children, header, isOpen, top, left, onClose }: Props) {
    const handleMouseSuppress = useCallback(
        (evt: MouseEvent) => {
            onClose();
            evt.stopPropagation();
        },
        [onClose],
    );

    if (!isOpen) return null;

    return (
        <Presenter onClick={handleMouseSuppress}>
            <Container style={{ top, left }}>
                {header && <Header>{header}</Header>}
                {children}
            </Container>
        </Presenter>
    );
}

PopupMenu.defaultProps = {
    header: undefined,
};

export default PopupMenu;
