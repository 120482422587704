import { MouseEvent, ReactElement, useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import useControl from '@hooks/useControl';
import ControlPackages from '@controls/index';
import ControlPresenter from '@components/ControlPresenter';
import useForm from '@hooks/useForm';

interface ContainerProps {
    isSelected?: boolean | undefined;
}

const Container = styled.div(({ isSelected }: ContainerProps) => [
    tw`border border-transparent w-full h-full overflow-hidden`,
    isSelected ? tw`border-blue-400 ring shadow ring-blue-400 ring-opacity-30` : '',
]);
const DecoratorViewport = tw.div`flex flex-col relative`;

interface Props {
    controlId: number;
}

function InheritedControlPresenter({ controlId }: Props) {
    const { selectedControlId, setSelectedControlId } = useForm();
    const { control } = useControl(controlId);
    const { getControlsByParentId } = useForm();

    const handleMouseUp = useCallback(
        (evt: MouseEvent) => {
            setSelectedControlId(controlId);
            evt.stopPropagation();
        },
        [setSelectedControlId, controlId],
    );

    if (!control) return null;
    if (control.properties.position.placement !== 'inherited') {
        throw new Error('Wrong presenter');
    }

    const childControls = getControlsByParentId(controlId);
    const controlPackage = ControlPackages[control.type];
    let children: ReactElement[] | undefined;

    if (childControls.length > 0) {
        children = childControls.map(child => (
            <ControlPresenter controlId={child.relativeId} key={`ctl-${child.relativeId}`} />
        ));
    }

    const renderedControl = controlPackage.renderDesigner(controlId, children);

    if (selectedControlId === controlId) {
        return (
            <DecoratorViewport id={`decorator-attachment-target-${controlId}`} onMouseUp={handleMouseUp}>
                <Container isSelected>{renderedControl}</Container>
            </DecoratorViewport>
        );
    }

    return (
        <DecoratorViewport>
            <Container onMouseUp={handleMouseUp}>{renderedControl}</Container>
        </DecoratorViewport>
    );
}

export default InheritedControlPresenter;
