/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    InheritedPositionPropsDto,
    instanceOfInheritedPositionPropsDto,
    InheritedPositionPropsDtoFromJSON,
    InheritedPositionPropsDtoFromJSONTyped,
    InheritedPositionPropsDtoToJSON,
} from './InheritedPositionPropsDto';
import {
    PrecisePositionPropsDto,
    instanceOfPrecisePositionPropsDto,
    PrecisePositionPropsDtoFromJSON,
    PrecisePositionPropsDtoFromJSONTyped,
    PrecisePositionPropsDtoToJSON,
} from './PrecisePositionPropsDto';
import {
    RelativePositionPropsDto,
    instanceOfRelativePositionPropsDto,
    RelativePositionPropsDtoFromJSON,
    RelativePositionPropsDtoFromJSONTyped,
    RelativePositionPropsDtoToJSON,
} from './RelativePositionPropsDto';

/**
 * @type ButtonPropsDtoPosition
 * 
 * @export
 */
export type ButtonPropsDtoPosition = { placement: 'inherited' } & InheritedPositionPropsDto | { placement: 'precise' } & PrecisePositionPropsDto | { placement: 'relative' } & RelativePositionPropsDto;

export function ButtonPropsDtoPositionFromJSON(json: any): ButtonPropsDtoPosition {
    return ButtonPropsDtoPositionFromJSONTyped(json, false);
}

export function ButtonPropsDtoPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ButtonPropsDtoPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['placement']) {
        case 'inherited':
            return {...InheritedPositionPropsDtoFromJSONTyped(json, true), placement: 'inherited'};
        case 'precise':
            return {...PrecisePositionPropsDtoFromJSONTyped(json, true), placement: 'precise'};
        case 'relative':
            return {...RelativePositionPropsDtoFromJSONTyped(json, true), placement: 'relative'};
        default:
            throw new Error(`No variant of ButtonPropsDtoPosition exists with 'placement=${json['placement']}'`);
    }
}

export function ButtonPropsDtoPositionToJSON(value?: ButtonPropsDtoPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['placement']) {
        case 'inherited':
            return InheritedPositionPropsDtoToJSON(value);
        case 'precise':
            return PrecisePositionPropsDtoToJSON(value);
        case 'relative':
            return RelativePositionPropsDtoToJSON(value);
        default:
            throw new Error(`No variant of ButtonPropsDtoPosition exists with 'placement=${value['placement']}'`);
    }

}

