import ControlPackages from '@controls/index';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import Property from '@components/Property';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useCallback } from 'react';
import useHoverHelpText from '@hooks/useHoverHelpText';

const Container = tw.div`flex-grow flex flex-col h-full`;
const PaneHeader = tw.div`flex flex-row items-center bg-gray-600`;
const ControlId = tw.div`flex items-center justify-center w-10 h-10 text-2xl leading-none mr-2 text-gray-200 bg-gray-700 flex-shrink-0 relative`;
const ControlIdTitle = tw.span`absolute text-[0.65rem] text-gray-400 leading-none top-0`;
const ControlTitle = tw.div`flex flex-col w-full`;
const ControlName = tw.span`text-sm font-bold text-gray-200 leading-none`;
const ControlTypeName = tw.span`text-gray-400 text-xs leading-none`;
const RenameButton = tw.button`text-gray-400 hover:text-white fill-current mr-2`;
const PropertyList = tw.div`flex-grow flex flex-col overflow-y-scroll`;
const PropertyContainer = tw.div`odd:bg-white even:bg-gray-100 px-1 py-2 border-b border-gray-300`;

interface Props {
    controlId: number;
}

function ControlProperties({ controlId }: Props) {
    const { control, rename } = useControl(controlId);
    const { onMouseEnter, onMouseLeave } = useHoverHelpText(
        'Relative ID, uniquely identifying this object on the window.',
    );
    const handleRename = useCallback(() => {
        if (!control) return;

        // eslint-disable-next-line no-alert
        const newName = prompt('Enter name for control:', control.name);

        if (newName) {
            rename(newName);
        }
    }, [control, rename]);

    if (!control) return null;

    const controlPackage = ControlPackages[control.type];

    return (
        <Container>
            <PaneHeader>
                <ControlId onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <span>{controlId}</span>
                    <ControlIdTitle>RID</ControlIdTitle>
                </ControlId>
                <ControlTitle>
                    <ControlName>{control.name}</ControlName>
                    <ControlTypeName>{control.type}</ControlTypeName>
                </ControlTitle>
                <RenameButton onClick={handleRename}>
                    <FontAwesomeIcon icon={solid('pencil')} />
                </RenameButton>
            </PaneHeader>
            <PropertyList>
                {Object.entries(controlPackage.propDescriptors).map(([key, value]) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <PropertyContainer key={`${controlId}-${key}`}>
                        {!!value && <Property name={key} descriptor={value} />}
                    </PropertyContainer>
                ))}
            </PropertyList>
        </Container>
    );
}

export default ControlProperties;
