import {
    MouseEvent as ReactMouseEvent,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { CSSProperties } from 'styled-components';
import tw, { styled } from 'twin.macro';
import useControl from '@hooks/useControl';
import ControlPackages from '@controls/index';
import WindowControl from '@controls/Window/Control';
import ControlPresenter from '@components/ControlPresenter';
import ComboBoxControl from '@controls/ComboBox/Control';
import useForm from '@hooks/useForm';

interface ContainerProps {
    isSelected?: boolean | undefined;
    isMovable?: boolean | undefined;
}

const Container = styled.div(({ isSelected, isMovable }: ContainerProps) => [
    tw`border border-transparent w-full h-full overflow-hidden`,
    isSelected ? tw`border-blue-400 ring shadow ring-blue-400 ring-opacity-30` : '',
    isMovable ? tw`cursor-move` : '',
]);
const DecoratorViewport = tw.div`flex flex-col relative`;
const TitleBar = tw.div`h-7 w-full bg-gray-400 flex items-center justify-between`;
const Title = tw.span`px-2`;
const CloseButton = tw.div`bg-red-400 h-7 w-7 text-white text-center font-bold`;

interface Props {
    controlId: number;
}

function PreciseControlPresenter({ controlId }: Props) {
    const { selectedControlId, setSelectedControlId } = useForm();
    const { control, updateProperties } = useControl(controlId);
    const { getControlsByParentId } = useForm();
    const [grid, setGrid] = useState<[number, number]>();
    const isLockedToGrid = useRef(false);
    const isResizing = useRef(false);
    const isDragging = useRef(false);
    const dragOrigin = useRef<[number, number]>();

    if (control?.properties?.position?.placement !== 'precise') {
        throw new Error('Wrong presenter');
    }

    useEffect(() => {
        const handleDown = (e: KeyboardEvent) => {
            isLockedToGrid.current ||= e.key === 'Shift';
        };
        const handleUp = (e: KeyboardEvent) => {
            if (e.key === 'Shift') isLockedToGrid.current = false;
        };

        document.addEventListener('keydown', handleDown);
        document.addEventListener('keyup', handleUp);

        return () => {
            document.removeEventListener('keydown', handleDown);
            document.removeEventListener('keyup', handleUp);
        };
    }, []);

    useEffect(() => {
        const listener = (ev: MouseEvent) => {
            if (ev.shiftKey && !grid) {
                setGrid([10, 10]);
            } else if (!ev.shiftKey && !!grid) {
                setGrid(undefined);
            }
        };

        document.addEventListener('mousemove', listener);
        return () => document.removeEventListener('mousemove', listener);
    }, [grid]);

    const handleResizeStart = useCallback(() => {
        isResizing.current = true;
    }, []);

    const handleResizeEnd = useCallback(
        (e: SyntheticEvent, data: ResizeCallbackData) => {
            e.stopPropagation();
            isResizing.current = false;

            let { width, height } = data.size;

            if (isLockedToGrid.current) {
                width = Math.round(width / 10) * 10;
                height = Math.round(height / 10) * 10;
            }

            updateProperties({
                position: {
                    width: Math.round(width),
                    height: Math.round(height),
                },
            });
        },
        [updateProperties],
    );

    const handleMouseDown = useCallback(
        (evt: ReactMouseEvent) => {
            if (
                isDragging.current ||
                isResizing.current ||
                control.type === 'window' ||
                control.properties.position.placement !== 'precise'
            ) {
                return;
            }

            const { left, top } = control.properties.position;

            dragOrigin.current = [evt.pageX - left, evt.pageY - top];
            isDragging.current = true;
        },
        [isDragging, control],
    );

    const handleMouseMove = useCallback(
        (evt: ReactMouseEvent) => {
            if (!isDragging.current || !dragOrigin.current) return;
            let left = evt.pageX - dragOrigin.current[0];
            let top = evt.pageY - dragOrigin.current[1];

            if (evt.shiftKey) {
                left = Math.round(left / 10) * 10;
                top = Math.round(top / 10) * 10;
            }

            left = Math.max(left, 0);
            top = Math.max(top, 0);

            updateProperties({
                position: { left, top },
            });
        },
        [isDragging, dragOrigin, updateProperties],
    );

    const handleMouseUp = useCallback(
        (evt: ReactMouseEvent) => {
            if (isDragging.current) {
                isDragging.current = false;
            } else {
                setSelectedControlId(controlId);
            }
            evt.stopPropagation();
        },
        [setSelectedControlId, controlId],
    );

    const childControls = getControlsByParentId(controlId);
    const controlPackage = ControlPackages[control.type];
    let children: ReactElement[] | undefined;

    if (childControls.length > 0) {
        children = childControls
            .sort((a, b) => a.properties.renderOrder - b.properties.renderOrder)
            .map(child => <ControlPresenter controlId={child.relativeId} key={`ctl-${child.relativeId}`} />);
    }

    const renderedControl = controlPackage.renderDesigner(controlId, children);
    let { height, width } = control.properties.position;
    const additionalStyle: Partial<CSSProperties> = {};

    if (control.type !== 'window' && control.properties.position.placement === 'precise') {
        additionalStyle.position = 'absolute';
        additionalStyle.top = control.properties.position.top;
        additionalStyle.left = control.properties.position.left;
    }

    if (control.type === 'combo-box') {
        // Special case: lock the height
        height = +(control as ComboBoxControl).properties.fontSize + 12;
    }

    if (controlPackage.getPreviewRenderSize) {
        const [previewWidth, previewHeight] = controlPackage.getPreviewRenderSize(control.properties);
        height = previewHeight || height;
        width = previewWidth || width;
    }

    if (selectedControlId === controlId) {
        return (
            <DecoratorViewport
                id={`decorator-attachment-target-${controlId}`}
                style={additionalStyle}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {control.type === 'window' && (
                    <TitleBar>
                        <Title>{(control as WindowControl).properties.caption}</Title>
                        <CloseButton>X</CloseButton>
                    </TitleBar>
                )}
                <ResizableBox
                    width={width + 2}
                    height={height + 2}
                    onResizeStart={handleResizeStart}
                    onResizeStop={handleResizeEnd}
                    draggableOpts={{ grid }}
                >
                    <Container isSelected isMovable={control.type !== 'window'}>
                        {renderedControl}
                    </Container>
                </ResizableBox>
            </DecoratorViewport>
        );
    }

    return (
        <DecoratorViewport>
            {control.type === 'window' && (
                <TitleBar>
                    <Title>{(control as WindowControl).properties.caption}</Title>
                    <CloseButton>X</CloseButton>
                </TitleBar>
            )}
            <Container onMouseUp={handleMouseUp} style={{ width: width + 2, height: height + 2, ...additionalStyle }}>
                {renderedControl}
            </Container>
        </DecoratorViewport>
    );
}

export default PreciseControlPresenter;
