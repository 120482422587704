import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import Radio from './Radio';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';

const createRadio = (parentId: number | undefined, renderOrder: number): Control => ({
    relativeId: -1,
    parentId,
    name: 'NewRadio',
    type: 'radio',
    actionFDO: '',
    properties: {
        caption: 'I am a radio',
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 96,
            height: 32,
        },
        bgColor: 'transparent',
        foreColor: '#000000',
        fontFace: 'arial',
        fontSize: 12,
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        frameStyle: '0',
        renderOrder,
        isEnabled: true,
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const radioPackage: ControlPackage<Props, Control> = {
    type: 'radio',
    icon: solid('circle-dot'),
    canHaveChildren: false,
    canHaveAction: true,
    validParentTypes: ['radio-list'],
    factory: createRadio,
    propDescriptors: PropsDescriptor,
    renderDesigner: controlId => createElement(Radio, { controlId }),
    upgradePropsVersion,
};

export default radioPackage;
