import useControl from '@hooks/useControl';
import PreciseControlPresenter from '@components/PreciseControlPresenter';
import InheritedControlPresenter from '@components/InheritedControlPresenter';

interface Props {
    controlId: number;
}

function ControlPresenter({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!control) return null;

    if (control.properties.position.placement === 'precise') return <PreciseControlPresenter controlId={controlId} />;
    if (control.properties.position.placement === 'inherited')
        return <InheritedControlPresenter controlId={controlId} />;

    throw new Error('Unsupported placement mode');
}

export default ControlPresenter;
