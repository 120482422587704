import { ActionButton } from '@components/common';
import { useCallback } from 'react';
import { useGlobal } from 'reactn';

function SignOutButton() {
    const [, setAuth] = useGlobal('auth');

    const handleSignOut = useCallback(() => {
        setAuth({
            accessToken: '',
            refreshToken: '',
            expiresAt: new Date(0),
            refreshAt: new Date(0),
        });
    }, [setAuth]);

    return <ActionButton onClick={handleSignOut}>Sign Out</ActionButton>;
}

export default SignOutButton;
