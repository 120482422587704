/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface CheckboxPropsDto
 */
export interface CheckboxPropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof CheckboxPropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckboxPropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {string}
     * @memberof CheckboxPropsDto
     */
    caption: string;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof CheckboxPropsDto
     */
    position: ButtonPropsDtoPosition;
    /**
     * 
     * @type {number}
     * @memberof CheckboxPropsDto
     */
    renderOrder: number;
}

/**
 * Check if a given object implements the CheckboxPropsDto interface.
 */
export function instanceOfCheckboxPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "renderOrder" in value;

    return isInstance;
}

export function CheckboxPropsDtoFromJSON(json: any): CheckboxPropsDto {
    return CheckboxPropsDtoFromJSONTyped(json, false);
}

export function CheckboxPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckboxPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'caption': json['caption'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
        'renderOrder': json['renderOrder'],
    };
}

export function CheckboxPropsDtoToJSON(value?: CheckboxPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'caption': value.caption,
        'position': ButtonPropsDtoPositionToJSON(value.position),
        'renderOrder': value.renderOrder,
    };
}

