/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormDtoControlsInner } from './FormDtoControlsInner';
import {
    FormDtoControlsInnerFromJSON,
    FormDtoControlsInnerFromJSONTyped,
    FormDtoControlsInnerToJSON,
} from './FormDtoControlsInner';

/**
 * 
 * @export
 * @interface FormDto
 */
export interface FormDto {
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    gid: string;
    /**
     * 
     * @type {Date}
     * @memberof FormDto
     */
    createDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormDto
     */
    lastUpdated: Date;
    /**
     * 
     * @type {number}
     * @memberof FormDto
     */
    lastRelativeId: number;
    /**
     * 
     * @type {Array<FormDtoControlsInner>}
     * @memberof FormDto
     */
    controls: Array<FormDtoControlsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof FormDto
     */
    isDeleted: boolean;
}

/**
 * Check if a given object implements the FormDto interface.
 */
export function instanceOfFormDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "gid" in value;
    isInstance = isInstance && "createDate" in value;
    isInstance = isInstance && "lastUpdated" in value;
    isInstance = isInstance && "lastRelativeId" in value;
    isInstance = isInstance && "controls" in value;
    isInstance = isInstance && "isDeleted" in value;

    return isInstance;
}

export function FormDtoFromJSON(json: any): FormDto {
    return FormDtoFromJSONTyped(json, false);
}

export function FormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'gid': json['gid'],
        'createDate': (new Date(json['createDate'])),
        'lastUpdated': (new Date(json['lastUpdated'])),
        'lastRelativeId': json['lastRelativeId'],
        'controls': ((json['controls'] as Array<any>).map(FormDtoControlsInnerFromJSON)),
        'isDeleted': json['isDeleted'],
    };
}

export function FormDtoToJSON(value?: FormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'gid': value.gid,
        'createDate': (value.createDate.toISOString()),
        'lastUpdated': (value.lastUpdated.toISOString()),
        'lastRelativeId': value.lastRelativeId,
        'controls': ((value.controls as Array<any>).map(FormDtoControlsInnerToJSON)),
        'isDeleted': value.isDeleted,
    };
}

