import { ControlPackage, ControlType } from './Base';
import buttonPackage from './Button';
import checkboxPackage from './Checkbox';
import checkboxListPackage from './CheckboxList';
import comboBoxPackage from './ComboBox';
import imagelistPackage from './Imagelist';
import imagelistItemPackage from './ImagelistItem';
import labelPackage from './Label';
import picturePackage from './Picture';
import radioPackage from './Radio';
import radioListPackage from './RadioList';
import textBoxPackage from './TextBox';
import windowPackage from './Window';

type ControlPackagesMap = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [K in ControlType]: ControlPackage<any, any>;
};

const ControlPackages: ControlPackagesMap = {
    window: windowPackage,
    button: buttonPackage,
    label: labelPackage,
    'checkbox-list': checkboxListPackage,
    checkbox: checkboxPackage,
    'radio-list': radioListPackage,
    radio: radioPackage,
    picture: picturePackage,
    imagelist: imagelistPackage,
    'imagelist-item': imagelistItemPackage,
    'combo-box': comboBoxPackage,
    textbox: textBoxPackage,
};

export default ControlPackages;
