import useApiClients from '@hooks/useApiClients';
import { ArtSource } from '@services/api';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import tw, { styled } from 'twin.macro';
import ArtBrowserAssetList from './ArtBrowserAssetList';

const ModalPresenter = tw.div`bg-black bg-opacity-60 flex items-center justify-center z-10 fixed top-0 left-0 w-screen h-screen`;
const ModalWindow = tw.div`w-2/3 h-2/3 flex flex-col bg-white rounded shadow border border-gray-200 p-4`;
const ModeSelectBar = tw.div`flex flex-row w-full items-start divide-x divide-gray-200`;
interface ModeButtonProps {
    isActive: boolean;
}
const ModeButton = styled.button(({ isActive }: ModeButtonProps) => [
    tw`border-t border-b border-gray-200 py-1 px-2 text-xs text-gray-500  first:(border-l rounded-tl-xl) last:(border-r! rounded-tr-xl) w-1/2 hover:(text-black)`,
    isActive ? tw`bg-blue-500 border-blue-500 text-white font-bold hover:(text-white)` : '',
]);
const BrowserContainer = tw.div`border-b border-l border-r border-gray-200 w-full overflow-y-auto flex-grow`;

const Actions = tw.div`flex flex-row justify-between space-x-2 mt-4`;
const UploadLabel = tw.label`cursor-pointer rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const HiddenInput = tw.input`hidden`;
const Action = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const ProcessingOverlay = tw.div`absolute w-full h-full z-50 bg-black opacity-75 cursor-wait`;

interface Props {
    isOpen: boolean;
    onSelectAsset: (globalId: string) => void;
    onCancel: () => void;
}

function ArtBrowser({ isOpen, onSelectAsset, onCancel }: Props) {
    const [mode, setMode] = useState<ArtSource>('system');
    const [isUploading, setIsUploading] = useState(false);
    const { artClient } = useApiClients();
    const handleMouseSuppress = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
    }, []);
    const handleSelectMode = useCallback((newMode: ArtSource) => () => setMode(newMode), []);
    const handleUpload = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target?.files?.length) {
                return;
            }

            try {
                const reader = new FileReader();
                setIsUploading(true);
                reader.addEventListener('load', async () => {
                    const encodedData = reader.result as string;
                    const uploadResult = await artClient.postNewAsset({
                        artUploadRequestDto: {
                            name: '',
                            contentB64: encodedData.substring(encodedData.indexOf(',') + 1),
                        },
                    });

                    if (uploadResult?.success && uploadResult.assetId) {
                        onSelectAsset(uploadResult.assetId);
                    }

                    setIsUploading(false);
                });
                reader.readAsDataURL(e.target.files[0]);
            } catch {
                setIsUploading(false);
                alert('Failed to upload asset, sorry. Contact a dev.');
            }
        },
        [artClient, onSelectAsset],
    );
    const handleCancel = useCallback(() => onCancel(), [onCancel]);

    if (!isOpen) return null;

    return (
        <ModalPresenter onMouseDown={handleMouseSuppress} onMouseUp={handleMouseSuppress}>
            <ModalWindow>
                <ModeSelectBar>
                    <ModeButton isActive={mode === 'system'} onClick={handleSelectMode('system')}>
                        System Assets
                    </ModeButton>
                    <ModeButton isActive={mode === 'owned'} onClick={handleSelectMode('owned')}>
                        My Assets
                    </ModeButton>
                </ModeSelectBar>
                <BrowserContainer>
                    <ArtBrowserAssetList source={mode} onSelectAsset={onSelectAsset} />
                </BrowserContainer>
                <Actions>
                    <UploadLabel>
                        <span>Upload Asset</span>
                        <HiddenInput onChange={handleUpload} type="file" accept=".png,.jpg,.jpeg,.bmp,.gif" />
                    </UploadLabel>
                    <Action onClick={handleCancel}>Cancel</Action>
                </Actions>
            </ModalWindow>
            {isUploading && <ProcessingOverlay />}
        </ModalPresenter>
    );
}

export default ArtBrowser;
