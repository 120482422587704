import { PaneHeaderButton } from '@components/common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '@hooks/useForm';
import useHoverHelpText from '@hooks/useHoverHelpText';
import { useCallback, useState } from 'react';
import tw from 'twin.macro';

const Container = tw.div`flex flex-row space-x-1 items-center text-gray-400`;

function GIDHelper() {
    const [copyState, setCopyState] = useState<'idle' | 'success'>('idle');
    const { gid } = useForm();
    const { onMouseEnter, onMouseLeave } = useHoverHelpText(
        'Copies the keyword to directly access the last published version of this form.',
    );
    const handleCopy = useCallback(() => {
        if (!gid) return;
        const textField = document.createElement('textarea');
        textField.innerText = `69420:${gid}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopyState('success');
        setTimeout(() => setCopyState('idle'), 1000);
    }, [gid]);

    if (!gid) return null;

    return (
        <Container>
            <span>{gid}</span>
            <PaneHeaderButton onClick={handleCopy} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {copyState === 'idle' && <FontAwesomeIcon size="sm" icon={solid('copy')} />}
                {copyState === 'success' && <FontAwesomeIcon size="sm" icon={solid('check')} />}
            </PaneHeaderButton>
        </Container>
    );
}

export default GIDHelper;
