import { ActionButton } from '@components/common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHoverHelpText from '@hooks/useHoverHelpText';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function CloseButton() {
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate('/');
    }, [navigate]);
    const { onMouseEnter, onMouseLeave } = useHoverHelpText('Close the project.');

    return (
        <ActionButton onClick={handleClose} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <FontAwesomeIcon icon={solid('times')} />
        </ActionButton>
    );
}

export default CloseButton;
