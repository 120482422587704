/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface ImagelistItemPropsDto
 */
export interface ImagelistItemPropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ImagelistItemPropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemPropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {number}
     * @memberof ImagelistItemPropsDto
     */
    renderOrder: number;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemPropsDto
     */
    caption: string;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemPropsDto
     */
    icon: string;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof ImagelistItemPropsDto
     */
    position: ButtonPropsDtoPosition;
}

/**
 * Check if a given object implements the ImagelistItemPropsDto interface.
 */
export function instanceOfImagelistItemPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "renderOrder" in value;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "icon" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function ImagelistItemPropsDtoFromJSON(json: any): ImagelistItemPropsDto {
    return ImagelistItemPropsDtoFromJSONTyped(json, false);
}

export function ImagelistItemPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagelistItemPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'renderOrder': json['renderOrder'],
        'caption': json['caption'],
        'icon': json['icon'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
    };
}

export function ImagelistItemPropsDtoToJSON(value?: ImagelistItemPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'renderOrder': value.renderOrder,
        'caption': value.caption,
        'icon': value.icon,
        'position': ButtonPropsDtoPositionToJSON(value.position),
    };
}

