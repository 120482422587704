/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createProvider } from 'reactn';
import { State } from 'reactn/default';
import { GlobalStyles } from 'twin.macro';
import reportWebVitals from './reportWebVitals';
import Navigator from './Navigator';

const INITIAL_STATE: State = {
    auth: {
        accessToken: '',
        refreshToken: '',
        expiresAt: new Date(),
        refreshAt: new Date(),
    },
    isGuest: false,
    selectedId: -1,
    assets: {
        system: {
            items: {},
            loading: false,
            lastToken: undefined,
        },
        owned: {
            items: {},
            loading: false,
            lastToken: undefined,
        },
    },
    helpText: '',
};
const SavedState = JSON.parse(window.sessionStorage.getItem('revpd_state') || '{}');
const Provider = createProvider(SavedState.auth?.accessToken ? SavedState : INITIAL_STATE);
Provider.addCallback(state => {
    const strippedState: State = {
        ...state,
        assets: {
            ...INITIAL_STATE.assets,
        },
    };
    window.sessionStorage.setItem('revpd_state', JSON.stringify(strippedState));
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <GlobalStyles />
        <Provider>
            <Navigator />
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { Provider };
