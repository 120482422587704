import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import tw, { styled } from 'twin.macro';
import FDOLanguage from './FDOLanguage';
import '@assets/prism.css';

const StyledEditor = styled(Editor)(() => [tw`font-mono border border-gray-300 bg-white h-full`]);

interface Props {
    value: string;
    onValueChange: (newValue: string) => void;
}

function FDOEditor({ value, onValueChange }: Props) {
    return (
        <StyledEditor
            highlight={code => Prism.highlight(code, FDOLanguage, 'fdo91')}
            value={value}
            onValueChange={onValueChange}
            padding={8}
        />
    );
}

export default FDOEditor;
