import { BaseControl, BaseProps } from '@controls/Base';
import { FontFaceToWebMap } from '@controls/FontProps';
import useControl from '@hooks/useControl';
import tw from 'twin.macro';
import ComboBoxControl from './Control';

const Container = tw.div`border border-black border-dotted flex flex-col overflow-y-scroll`;

interface Props {
    controlId: number;
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

function isValidType(control: BaseControl<BaseProps> | undefined): control is ComboBoxControl {
    return !!control && control.type === 'combo-box';
}

function ComboBox({ controlId, children }: Props) {
    const { control } = useControl(controlId);
    if (!isValidType(control)) throw new Error('Bad render call');

    let [width, height] = [150, 50];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = +control.properties.fontSize + 12;
    }

    const fontFamily = FontFaceToWebMap[control.properties.fontFace];
    const fontSize = `${control.properties.fontSize}pt`;
    const fontWeight = control.properties.fontBold ? 'bold' : undefined;
    const fontStyle = control.properties.fontItalic ? 'italic' : undefined;
    const textDecoration = control.properties.fontUnderline ? 'underline' : undefined;

    return (
        <Container
            style={{
                width,
                height,
                fontFamily,
                fontSize,
                fontWeight,
                fontStyle,
                textDecoration,
            }}
        >
            {children}
        </Container>
    );
}

ComboBox.defaultProps = {
    children: undefined,
};

export default ComboBox;
