import { EnumPropValue, PropDescriptor } from './PropDescriptors';
import { AppearancePropGroup } from './PropGroups';

type FontFace =
    | 'arial'
    | 'courier'
    | 'times_roman'
    | 'system'
    | 'fixed_system'
    | 'ms_serif'
    | 'ms_sans_serif'
    | 'small_fonts'
    | 'courier_new';

export interface FontProps {
    fontFace: FontFace;
    fontSize: number;
    fontBold: boolean;
    fontItalic: boolean;
    fontUnderline: boolean;
}

const FontFaceOptions: EnumPropValue[] = [
    { name: 'Arial', value: 'arial' },
    { name: 'Courier', value: 'courier' },
    { name: 'Times Roman', value: 'times_roman' },
    { name: 'System', value: 'system' },
    { name: 'Fixed System', value: 'fixed_system' },
    { name: 'MS Serif', value: 'ms_serif' },
    { name: 'MS Sans Serif', value: 'ms_sans_serif' },
    { name: 'Small Fonts', value: 'small_fonts' },
    { name: 'Courier New', value: 'courier_new' },
];

const FontPropDescriptors: Record<keyof FontProps, PropDescriptor> = {
    fontFace: {
        inputType: 'enum',
        displayName: 'Font Face',
        helpText: 'The font to be used for any text on the object.',
        propGroup: AppearancePropGroup,
        options: FontFaceOptions,
    },
    fontSize: {
        inputType: 'number',
        displayName: 'Font Size',
        helpText: 'The point size in which to display text.',
        propGroup: AppearancePropGroup,
        minValue: 6,
    },
    fontBold: {
        inputType: 'boolean',
        displayName: 'Bold',
        helpText: 'Makes the text bold.',
        propGroup: AppearancePropGroup,
    },
    fontItalic: {
        inputType: 'boolean',
        displayName: 'Italic',
        helpText: 'Makes the text italic.',
        propGroup: AppearancePropGroup,
    },
    fontUnderline: {
        inputType: 'boolean',
        displayName: 'Underline',
        helpText: 'Makes the text underlined.',
        propGroup: AppearancePropGroup,
    },
};

const FontFaceToWebMap: Record<FontFace, string> = {
    arial: 'Arial',
    courier: 'Courier',
    times_roman: 'Times New Roman',
    system: 'SystemBold',
    fixed_system: 'FixedSys',
    ms_serif: 'serif',
    ms_sans_serif: 'MSSansSerif',
    small_fonts: 'Impact',
    courier_new: 'Courier New',
};

export { FontPropDescriptors, FontFaceToWebMap };
