import { getDataUrl } from 'src/util';
import tw from 'twin.macro';

const Container = tw.button`bg-gray-200 p-2 self-center flex flex-col items-center space-y-2 rounded text-gray-600 hover:(bg-gray-100 text-black border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const Title = tw.span`text-xs`;
const PreviewImage = tw.img`max-w-[8rem] max-h-[8rem]`;

interface Props {
    gid: string;
    preview: string;
    onSelect: () => void;
}

function ArtBrowserAsset({ gid, preview, onSelect }: Props) {
    return (
        <Container onClick={onSelect}>
            <PreviewImage src={getDataUrl(preview)} alt={gid} />
            <Title>{gid}</Title>
        </Container>
    );
}

export default ArtBrowserAsset;
