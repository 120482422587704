import tw, { styled } from 'twin.macro';
import { PropDescriptor } from '@controls/PropDescriptors';
import { Buffer } from 'buffer';
import useControl from '@hooks/useControl';
import BoolProperty from '@components/BoolProperty';
import ColorProperty from '@components/ColorProperty';
import EnumProperty from '@components/EnumProperty';
import NumberProperty from '@components/NumberProperty';
import TextProperty from '@components/TextProperty';
import useForm from '@hooks/useForm';
import { useMemo } from 'react';
import ArtProperty from './ArtProperty';
import ReferenceImageProperty from './ReferenceImageProperty';
import PrecisePositionProperty from './PrecisePositionProperty';

interface StyleProps {
    isSingleLine: boolean;
}

const Container = styled.label(({ isSingleLine }: StyleProps) => [
    tw`flex space-y-1`,
    isSingleLine ? tw`w-full flex-row items-start justify-between mr-auto` : tw`flex-col`,
]);
const TextContainer = tw.div`flex flex-col`;
const Name = tw.span`text-xs font-bold`;
const HelpText = tw.span`text-xs text-gray-600`;

interface Props {
    name: string;
    descriptor: PropDescriptor;
}

function Property({ name, descriptor }: Props) {
    const { selectedControlId } = useForm();
    const { control } = useControl(selectedControlId!);
    const elementId = useMemo(() => {
        if (!selectedControlId || !name) return '';
        return Buffer.from(`${selectedControlId}_${name}`)
            .toString('base64')
            .replaceAll('+', '-')
            .replaceAll('/', '_')
            .replaceAll('=', '');
    }, [selectedControlId, name]);

    if (!selectedControlId || !control) return null;

    return (
        <Container htmlFor={elementId} isSingleLine={descriptor.inputType === 'boolean'}>
            <TextContainer>
                <Name>{descriptor.displayName}</Name>
                {descriptor.helpText && <HelpText>{descriptor.helpText}</HelpText>}
            </TextContainer>
            {descriptor.inputType === 'text' && <TextProperty id={elementId} name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'color' && <ColorProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'number' && <NumberProperty id={elementId} name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'enum' && <EnumProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'boolean' && <BoolProperty id={elementId} name={name} />}
            {descriptor.inputType === 'art' && <ArtProperty name={name} />}
            {descriptor.inputType === 'reference-image' && <ReferenceImageProperty name={name} />}
            {descriptor.inputType === 'position' && control.properties.position.placement === 'precise' && (
                <PrecisePositionProperty name={name} />
            )}
        </Container>
    );
}

export default Property;
