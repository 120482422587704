/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ActionControl, BaseControl, BaseProps } from '@controls/Base';
import { useDebouncedCallback } from 'use-debounce';
import { FormDtoControlsInner } from '@services/api';
import useProject from './useProject';
import useApiClients from './useApiClients';

interface IFormContext {
    addControl: (newControl: BaseControl<BaseProps>) => number;
    removeControl: (controlId: number) => void;
    getControlById: (controlId: number) => BaseControl<BaseProps> | undefined;
    getControlsByParentId: (controlId: number) => BaseControl<BaseProps>[];
    getWindow: () => BaseControl<BaseProps> | undefined;
    swapControlRenderOrder: (controlId: number, desiredRenderOrder: number) => void;
    updateControl: (controlId: number, update: Partial<BaseControl<BaseProps> | ActionControl>) => void;
    preview: () => Promise<void>;
    isLoading: boolean;
    isDirty: boolean;
    isSaving: boolean;
    selectedControlId: number | undefined;
    gid: string | undefined;
    setSelectedControlId: (controlId: number | undefined) => void;
}

const FormContextInitial: IFormContext = {
    addControl: () => 0,
    removeControl: () => {},
    getControlById: () => undefined,
    getControlsByParentId: () => [],
    getWindow: () => undefined,
    updateControl: () => {},
    swapControlRenderOrder: () => {},
    preview: async () => {},
    isLoading: false,
    isSaving: false,
    isDirty: false,
    selectedControlId: undefined,
    gid: undefined,
    setSelectedControlId: () => {},
};

const FormContext = createContext(FormContextInitial);

interface Props {
    formId: string | undefined;
    children: React.ReactNode;
}

export function FormContextProvider({ formId, children }: Props) {
    const { project } = useProject();
    const loadedFormId = useRef<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [gid, setGid] = useState<string>();
    const lastRelativeId = useRef(0);
    const [controls, setControls] = useState<Record<number, BaseControl<BaseProps>>>({});
    const { projectsClient } = useApiClients();
    const [selectedControlId, setSelectedControlId] = useState<number>();
    const isDirty = useRef(false);

    useEffect(() => {
        if (formId === loadedFormId.current || !projectsClient || !project || !formId) return;
        loadedFormId.current = formId;
        isDirty.current = false;
        setIsLoading(true);

        const loadFormData = async () => {
            const result = await projectsClient.getForm({ projectId: project.id, formId });
            lastRelativeId.current = result.lastRelativeId;
            setSelectedControlId(result.controls.find(c => !c.parentId)?.relativeId);
            setGid(result?.gid || undefined);
            setControls(result.controls.reduce((prev, curr) => ({ ...prev, [curr.relativeId]: curr }), {}));
            setIsLoading(false);
        };
        loadFormData();
    }, [formId, project, projectsClient]);

    const throttledUpdate = useDebouncedCallback(
        (projectId: string, formIdVal: string, lastRelativeIdVal: number, controlsVal: BaseControl<BaseProps>[]) => {
            isDirty.current = false;
            setIsSaving(true);
            projectsClient
                .updateForm({
                    projectId,
                    formId: formIdVal,
                    updateFormDto: {
                        lastRelativeId: lastRelativeIdVal,
                        controls: controlsVal as unknown as FormDtoControlsInner[],
                    },
                })
                .then(() => {
                    setIsSaving(false);
                });
        },
        1000,
        {
            maxWait: 5000,
        },
    );

    useEffect(() => {
        if (project && formId && controls && isDirty.current) {
            throttledUpdate(project?.id, formId, lastRelativeId.current, Object.values(controls));
        }
    }, [project, formId, controls, throttledUpdate]);

    const addControl = useCallback((newControl: BaseControl<BaseProps>) => {
        lastRelativeId.current += 1;
        const controlWithId = { ...newControl, relativeId: lastRelativeId.current };
        setControls(c => ({ ...c, [controlWithId.relativeId]: controlWithId }));
        isDirty.current = true;

        return controlWithId.relativeId;
    }, []);

    const removeControl = useCallback(
        (controlId: number) => {
            if (controlId === selectedControlId) {
                setSelectedControlId(undefined);
            }

            setControls(controlList => {
                const result = { ...controlList };
                const controlsToRemove = new Set<number>();

                const walkControl = (id: number) => {
                    controlsToRemove.add(id);
                    const foundChildren = Object.values(controlList).filter(c => c.parentId === id);
                    foundChildren.forEach(c => walkControl(c.relativeId));
                };

                walkControl(controlId);
                controlsToRemove.forEach(removeId => delete result[removeId]);
                isDirty.current = true;

                return result;
            });
        },
        [selectedControlId],
    );

    const getControlById = useCallback((controlId: number) => controls[controlId], [controls]);
    const getControlsByParentId = useCallback(
        (parentControlId: number) => Object.values(controls).filter(c => c.parentId === parentControlId),
        [controls],
    );
    const getWindow = useCallback(() => Object.values(controls).find(c => c.type === 'window'), [controls]);
    const updateControl = useCallback((controlId: number, update: Partial<BaseControl<BaseProps> | ActionControl>) => {
        setControls(controlList => ({
            ...controlList,
            [controlId]: {
                ...controlList[controlId],
                ...update,
            },
        }));

        isDirty.current = true;
    }, []);
    const swapControlRenderOrder = useCallback(
        (controlId: number, desiredRenderOrder: number) => {
            const sourceControl = controls[controlId];
            if (!sourceControl) return;

            const targetControl = Object.values(controls).find(
                c => c.parentId === sourceControl.parentId && c.properties.renderOrder === desiredRenderOrder,
            );
            if (!targetControl) return;

            const originalRenderOrder = sourceControl.properties.renderOrder;

            updateControl(sourceControl.relativeId, {
                properties: { ...sourceControl.properties, renderOrder: desiredRenderOrder },
            });
            updateControl(targetControl.relativeId, {
                properties: { ...targetControl.properties, renderOrder: originalRenderOrder },
            });
        },
        [controls, updateControl],
    );
    const preview = useCallback(async () => {
        if (isDirty.current || !project || !formId) {
            // eslint-disable-next-line no-console
            console.warn('not previewing', isDirty.current, !project, !formId);
            return;
        }

        await projectsClient.previewForm({
            projectId: project.id,
            formId,
        });
    }, [project, formId, projectsClient]);

    const value = useMemo(
        () => ({
            addControl,
            removeControl,
            getControlById,
            getControlsByParentId,
            getWindow,
            updateControl,
            swapControlRenderOrder,
            preview,
            isLoading,
            isSaving,
            gid,
            selectedControlId,
            isDirty: isDirty.current,
            setSelectedControlId,
        }),
        [
            addControl,
            removeControl,
            getControlById,
            getControlsByParentId,
            getWindow,
            updateControl,
            swapControlRenderOrder,
            preview,
            isLoading,
            isSaving,
            gid,
            selectedControlId,
            setSelectedControlId,
        ],
    );

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export default function useForm() {
    const context = useContext(FormContext);

    if (context === undefined) {
        throw new Error('useForm must be used within a FormContext');
    }

    return context;
}
