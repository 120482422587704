import { MouseEvent, useCallback } from 'react';
import tw from 'twin.macro';
import ControlPresenter from '@components/ControlPresenter';
import useForm from '@hooks/useForm';
import DecoratorMenu from './DecoratorMenu';

const Wrapper = tw.div`flex-grow flex items-start justify-start overflow-scroll px-6 pt-8 relative`;
const Container = tw.div`flex-grow flex items-start justify-start`;

function DesignerSurface() {
    const { getWindow } = useForm();
    const { selectedControlId, setSelectedControlId } = useForm();
    const rootWindow = getWindow();
    const handleClick = useCallback(
        (evt: MouseEvent) => {
            if (evt.target === evt.currentTarget) {
                setSelectedControlId(undefined);
            }
        },
        [setSelectedControlId],
    );

    return (
        <Wrapper id="decorator-root" onClick={handleClick}>
            <Container>
                {rootWindow && <ControlPresenter controlId={rootWindow.relativeId} />}
                {selectedControlId && <DecoratorMenu controlId={selectedControlId} />}
            </Container>
        </Wrapper>
    );
}

export default DesignerSurface;
