import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import CheckboxList from './CheckboxList';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';

const createCheckboxList = (parentId: number | undefined, renderOrder: number): Control => ({
    relativeId: -1,
    parentId,
    name: 'NewCheckboxList',
    type: 'checkbox-list',
    properties: {
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 96,
            height: 32,
        },
        isEnabled: true,
        frameStyle: '0',
        renderOrder,
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const checkboxListPackage: ControlPackage<Props, Control> = {
    type: 'checkbox-list',
    icon: solid('list-check'),
    canHaveChildren: true,
    canHaveAction: false,
    validParentTypes: ['window'],
    factory: createCheckboxList,
    propDescriptors: PropsDescriptor,
    // eslint-disable-next-line react/no-children-prop
    renderDesigner: (controlId, children) => createElement(CheckboxList, { controlId, children }),
    upgradePropsVersion,
};

export default checkboxListPackage;
