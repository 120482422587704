import useProject from '@hooks/useProject';
import tw, { styled } from 'twin.macro';
import AddFormButton from './AddFormButton';
import ProjectExplorerTreeNode from './ProjectExplorerTreeNode';
import PublishButton from './PublishButton';

const Pane = tw.div`h-1/2 w-full`;
const Header = tw.div`w-full flex flex-row bg-gray-600 text-gray-200 p-2 justify-between leading-none`;
const PaneHeader = tw.p`text-xs font-bold`;
const Actions = tw.div`flex flex-row space-x-2`;
const Tree = styled.div(() => [tw`px-2 overflow-y-auto`, 'max-height: calc(100% - 32px);']);

function ProjectExplorer() {
    const { project } = useProject();

    return (
        <Pane>
            <Header>
                <PaneHeader>Project Explorer</PaneHeader>
                <Actions>
                    <PublishButton />
                    <AddFormButton />
                </Actions>
            </Header>
            <Tree>
                {project?.forms.map(f => (
                    <ProjectExplorerTreeNode key={f.id} form={f} />
                ))}
            </Tree>
        </Pane>
    );
}

export default ProjectExplorer;
