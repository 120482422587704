import useArtList from '@hooks/useArtList';
import { ArtSource } from '@services/api';
import { UIEvent, useCallback } from 'react';
import tw from 'twin.macro';
import ArtBrowserAsset from './ArtBrowserAsset';

const AssetList = tw.div`flex flex-row flex-wrap h-full overflow-y-scroll space-x-2 space-y-2 p-2 justify-start`;

interface Props {
    source: ArtSource;
    onSelectAsset: (globalId: string) => void;
}

function ArtBrowserAssetList({ source, onSelectAsset }: Props) {
    const { assets, isLoading, hasMore, loadMore } = useArtList(source);
    const handleScroll = useCallback(
        (evt: UIEvent) => {
            const distanceFromBottom = Math.abs(
                evt.currentTarget.scrollTop + evt.currentTarget.clientHeight - evt.currentTarget.scrollHeight,
            );

            if (!isLoading && hasMore && distanceFromBottom < 100) {
                loadMore();
            }
        },
        [isLoading, hasMore, loadMore],
    );

    return (
        <AssetList onScroll={handleScroll}>
            {Object.values(assets).map(art => (
                <ArtBrowserAsset
                    key={art.gid}
                    gid={art.gid}
                    preview={art.preview}
                    onSelect={() => onSelectAsset(art.gid)}
                />
            ))}
        </AssetList>
    );
}

export default ArtBrowserAssetList;
