/* eslint-disable import/prefer-default-export */
export function hexToRGB(color: string) {
    if (/^#([a-f0-9]{3}){1,2}$/.test(color)) {
        let colorHex: string;

        if (color.length === 4) {
            colorHex = `0x${[color[1], color[1], color[2], color[2], color[3], color[3]].join('')}`;
        } else {
            colorHex = `0x${color.substring(1)}`;
        }

        const colorInt = +colorHex;
        // eslint-disable-next-line no-bitwise
        return [(colorInt >> 16) & 255, (colorInt >> 8) & 255, colorInt & 255];
    }

    throw new Error('Invalid color');
}

export function getDataUrl(b64: string | undefined) {
    if (!b64) return undefined;

    return `data:image/png;base64,${b64}`;
}

export function sleep(ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
