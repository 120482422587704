import { BaseControl, BaseProps } from '@controls/Base';
import { FontFaceToWebMap } from '@controls/FontProps';
import useArt from '@hooks/useArt';
import useControl from '@hooks/useControl';
import { getDataUrl } from 'src/util';
import tw from 'twin.macro';
import ButtonControl from './Control';

const Wrapper = tw.div`flex flex-col`;
const Container = tw.div`border border-black shadow overflow-hidden select-none flex items-start justify-center text-xs`;
const Image = tw.img`max-w-none`;
const ExternalCaption = tw.span`leading-none text-center`;

interface Props {
    controlId: number;
}

function isButton(control: BaseControl<BaseProps> | undefined): control is ButtonControl {
    return !!control && control.type === 'button';
}

function Button({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isButton(control)) throw new Error('Bad render call');
    let [width, height]: [number | undefined, number | undefined] = [undefined, undefined];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.height;
    }

    const fontFamily = FontFaceToWebMap[control.properties.fontFace];
    const fontSize = `${control.properties.fontSize}pt`;
    const fontWeight = control.properties.fontBold ? 'bold' : undefined;
    const fontStyle = control.properties.fontItalic ? 'italic' : undefined;
    const textDecoration = control.properties.fontUnderline ? 'underline' : undefined;
    const { asset } = useArt(control?.properties.image);

    return (
        <Wrapper>
            <Container
                style={{
                    backgroundColor: control.properties.bgColor,
                    width,
                    height,
                    fontFamily,
                    fontSize,
                    fontWeight,
                    fontStyle,
                    textDecoration,
                }}
            >
                {!control.properties.image && (
                    <span
                        style={{
                            color: control.properties.foreColor,
                            lineHeight: `${control.properties.fontSize}pt`,
                            margin: '6px 2px',
                        }}
                    >
                        {control.properties.caption}
                    </span>
                )}
                {/* {control.properties.image && control.properties.triggerStyle === 'picture' && (
                    <ShadowImage
                        style={{ margin: 'auto' }}
                        onDragStart={e => e.preventDefault()}
                        src={getArtPreviewUrl(control.properties.image)}
                        alt=""
                    />
                )} */}
                {control.properties.image && (
                    <Image
                        style={{ margin: 'auto' }}
                        onDragStart={e => e.preventDefault()}
                        src={getDataUrl(asset?.preview)}
                        alt=""
                    />
                )}
            </Container>
            {control.properties.image && (
                <ExternalCaption
                    style={{
                        color: control.properties.foreColor,
                        fontFamily,
                        fontSize,
                        fontWeight,
                        fontStyle,
                        textDecoration,
                        lineHeight: `${control.properties.fontSize}pt`,
                    }}
                >
                    {control.properties.caption}
                </ExternalCaption>
            )}
        </Wrapper>
    );
}

export default Button;
