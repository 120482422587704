import { caption, BasePropDescriptors } from '@controls/CommonProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    ...BasePropDescriptors,
    caption: caption(),
};

export default PropsDescriptor;
