import { foreColor, BasePropDescriptors } from '@controls/CommonProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import { BehaviorPropGroup } from '@controls/PropGroups';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    ...BasePropDescriptors,
    foreColor: foreColor(),
    isMultiline: {
        inputType: 'boolean',
        displayName: 'Is Multiline?',
        helpText: '',
        propGroup: BehaviorPropGroup,
    },
};

export default PropsDescriptor;
