/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WindowPropsDto } from './WindowPropsDto';
import {
    WindowPropsDtoFromJSON,
    WindowPropsDtoFromJSONTyped,
    WindowPropsDtoToJSON,
} from './WindowPropsDto';

/**
 * 
 * @export
 * @interface WindowControlDto
 */
export interface WindowControlDto {
    /**
     * 
     * @type {WindowPropsDto}
     * @memberof WindowControlDto
     */
    properties: WindowPropsDto;
    /**
     * 
     * @type {string}
     * @memberof WindowControlDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof WindowControlDto
     */
    relativeId: number;
    /**
     * 
     * @type {number}
     * @memberof WindowControlDto
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof WindowControlDto
     */
    name: string;
}

/**
 * Check if a given object implements the WindowControlDto interface.
 */
export function instanceOfWindowControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "relativeId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function WindowControlDtoFromJSON(json: any): WindowControlDto {
    return WindowControlDtoFromJSONTyped(json, false);
}

export function WindowControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindowControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': WindowPropsDtoFromJSON(json['properties']),
        'type': json['type'],
        'relativeId': json['relativeId'],
        'parentId': json['parentId'],
        'name': json['name'],
    };
}

export function WindowControlDtoToJSON(value?: WindowControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': WindowPropsDtoToJSON(value.properties),
        'type': value.type,
        'relativeId': value.relativeId,
        'parentId': value.parentId,
        'name': value.name,
    };
}

