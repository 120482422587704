import { useCallback } from 'react';
import tw from 'twin.macro';
import { TextPropDescriptor } from '@controls/PropDescriptors';
import useControl from '@hooks/useControl';
import useForm from '@hooks/useForm';

const Input = tw.input`rounded border border-gray-200 p-1 mt-1 text-sm hover:(border-blue-400) focus:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    name: string;
    descriptor: TextPropDescriptor;
    id: string;
}

function TextProperty({ id, name, descriptor }: Props) {
    const { selectedControlId } = useForm();
    const { control, updateProperties } = useControl(selectedControlId!);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = control.properties[name];
    const handleChange = useCallback(
        (newValue: string) => {
            const newProps = {
                [name]: newValue,
            };
            updateProperties(newProps);
        },
        [name, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <Input
            id={id}
            type="text"
            value={value}
            onChange={v => handleChange(v.currentTarget.value)}
            minLength={descriptor.minLength}
            maxLength={descriptor.maxLength}
        />
    );
}

export default TextProperty;
