import { BasePropDescriptors } from '@controls/CommonProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import { AppearancePropGroup } from '@controls/PropGroups';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    ...BasePropDescriptors,
    asset: {
        inputType: 'art',
        propGroup: AppearancePropGroup,
        displayName: 'Asset Image',
        helpText: 'The asset image for the object.',
    },
    isAnimated: {
        inputType: 'boolean',
        propGroup: AppearancePropGroup,
        displayName: 'Is Animated',
        helpText: 'When set, the art asset will be animated.',
    },
    animationRate: {
        inputType: 'number',
        propGroup: AppearancePropGroup,
        displayName: 'Animation Rate',
        helpText: 'When IsAnimated, the delay (in ms) between frames.',
    },
};

export default PropsDescriptor;
