import { ProjectDto, ProjectType } from '@services/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import useApiClients from './useApiClients';

export default function useProjectList() {
    const loadLock = useRef(false);
    const [isReady, setIsReady] = useState(false);
    const { projectsClient } = useApiClients();
    const [projects, setProjects] = useState<ProjectDto[]>([]);

    useEffect(() => {
        if (!projectsClient || loadLock.current) return;
        loadLock.current = true;

        const load = async () => {
            setProjects(await projectsClient.list());
            setIsReady(true);
        };
        load();
    }, [projectsClient]);

    const createProject = useCallback(
        async (name: string, type: ProjectType) => {
            setIsReady(false);
            const result = await projectsClient.newProject({
                newProjectDto: {
                    name,
                    type,
                },
            });

            setIsReady(true);
            return result;
        },
        [projectsClient],
    );

    return { projects, isReady, createProject };
}
