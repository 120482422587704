import { BaseControl, BaseProps } from '@controls/Base';
import useControl from '@hooks/useControl';
import tw from 'twin.macro';
import RadioListControl from './Control';

const Container = tw.div`border border-black border-dotted`;

interface Props {
    controlId: number;
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

function isValidType(control: BaseControl<BaseProps> | undefined): control is RadioListControl {
    return !!control && control.type === 'radio-list';
}

function CheckboxList({ controlId, children }: Props) {
    const { control } = useControl(controlId);
    if (!isValidType(control)) throw new Error('Bad render call');

    let [width, height] = [150, 50];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.height;
    }

    return (
        <Container
            style={{
                width,
                height,
            }}
        >
            {children}
        </Container>
    );
}

CheckboxList.defaultProps = {
    children: undefined,
};

export default CheckboxList;
