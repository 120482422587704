/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ArtSource = {
    System: 'system',
    Owned: 'owned'
} as const;
export type ArtSource = typeof ArtSource[keyof typeof ArtSource];


export function ArtSourceFromJSON(json: any): ArtSource {
    return ArtSourceFromJSONTyped(json, false);
}

export function ArtSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtSource {
    return json as ArtSource;
}

export function ArtSourceToJSON(value?: ArtSource | null): any {
    return value as any;
}

