import { useDispatch, useGlobal, useRef } from 'reactn';
import { ArtListItemDto } from 'src/models';
import useApiClients from './useApiClients';

export default function useArt(gid: string | undefined) {
    const isLoading = useRef(false);
    const [assets] = useGlobal('assets');
    const { artClient } = useApiClients();

    const appendAsset = useDispatch(
        (state, source: 'system' | 'owned', val: ArtListItemDto) => ({
            ...state,
            [source]: {
                ...state[source],
                items: {
                    ...state[source].items,
                    [val.gid]: val,
                },
            },
        }),
        'assets',
    );

    if (!gid) {
        return { asset: undefined };
    }

    const systemAsset = assets.system.items[gid];
    const userAsset = assets.owned.items[gid];

    if (!systemAsset && !userAsset && !isLoading.current) {
        isLoading.current = true;
        artClient
            .getAsset({ id: gid })
            .then(val => {
                if (val && val.source) {
                    appendAsset(val.source, val).then(() => {
                        isLoading.current = false;
                    });
                }
            })
            .catch(() => {
                isLoading.current = false;
            });
    }

    return { asset: systemAsset || userAsset };
}
