/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeRequestDto
 */
export interface ExchangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRequestDto
     */
    authCode: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRequestDto
     */
    state: string;
}

/**
 * Check if a given object implements the ExchangeRequestDto interface.
 */
export function instanceOfExchangeRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "authCode" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function ExchangeRequestDtoFromJSON(json: any): ExchangeRequestDto {
    return ExchangeRequestDtoFromJSONTyped(json, false);
}

export function ExchangeRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authCode': json['authCode'],
        'state': json['state'],
    };
}

export function ExchangeRequestDtoToJSON(value?: ExchangeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authCode': value.authCode,
        'state': value.state,
    };
}

