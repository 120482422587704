import { useCallback } from 'react';
import tw from 'twin.macro';
import { NumberPropDescriptor } from '@controls/PropDescriptors';
import useControl from '@hooks/useControl';
import useForm from '@hooks/useForm';

const Input = tw.input`rounded border border-gray-200 p-1 mt-1 hover:(border-blue-400) focus:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    id: string;
    name: string;
    descriptor: NumberPropDescriptor;
}

function NumberProperty({ id, name, descriptor }: Props) {
    const { selectedControlId } = useForm();
    const { control, updateProperties } = useControl(selectedControlId!);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = control.properties[name];
    const handleChange = useCallback(
        (newValue: string) => {
            updateProperties({
                [name]: +newValue,
            });
        },
        [name, updateProperties],
    );
    const handleBlur = useCallback(
        (newValue: string) => {
            let clampedValue = +newValue;
            if (descriptor.minValue) clampedValue = Math.max(clampedValue, descriptor.minValue);
            if (descriptor.maxValue) clampedValue = Math.min(clampedValue, descriptor.maxValue);

            updateProperties({
                [name]: clampedValue,
            });
        },
        [name, descriptor, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <Input
            id={id}
            type="number"
            value={value}
            onBlur={v => handleBlur(v.currentTarget.value)}
            onChange={v => handleChange(v.currentTarget.value)}
            min={descriptor.minValue}
            max={descriptor.maxValue}
        />
    );
}

export default NumberProperty;
