import { PaneHeaderButton } from '@components/common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHoverHelpText from '@hooks/useHoverHelpText';
import useProject from '@hooks/useProject';
import { useCallback } from 'react';

function AddFormButton() {
    const { onMouseEnter, onMouseLeave } = useHoverHelpText('Adds a new empty form to the project.');
    const { newForm, setActiveFormId } = useProject();

    const handleAddWindow = useCallback(async () => {
        if (!newForm || !setActiveFormId) return;

        // eslint-disable-next-line no-alert
        const name = prompt('Name of the new form?');

        if (name) {
            const formId = await newForm(name);
            setActiveFormId(formId);
        }
    }, [newForm, setActiveFormId]);

    return (
        <PaneHeaderButton
            type="button"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={handleAddWindow}
        >
            <FontAwesomeIcon size="lg" icon={solid('plus')} />
            <span>Add Form</span>
        </PaneHeaderButton>
    );
}

export default AddFormButton;
