/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckboxListPropsDto } from './CheckboxListPropsDto';
import {
    CheckboxListPropsDtoFromJSON,
    CheckboxListPropsDtoFromJSONTyped,
    CheckboxListPropsDtoToJSON,
} from './CheckboxListPropsDto';

/**
 * 
 * @export
 * @interface CheckboxListControlDto
 */
export interface CheckboxListControlDto {
    /**
     * 
     * @type {CheckboxListPropsDto}
     * @memberof CheckboxListControlDto
     */
    properties: CheckboxListPropsDto;
    /**
     * 
     * @type {string}
     * @memberof CheckboxListControlDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CheckboxListControlDto
     */
    relativeId: number;
    /**
     * 
     * @type {number}
     * @memberof CheckboxListControlDto
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckboxListControlDto
     */
    name: string;
}

/**
 * Check if a given object implements the CheckboxListControlDto interface.
 */
export function instanceOfCheckboxListControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "relativeId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CheckboxListControlDtoFromJSON(json: any): CheckboxListControlDto {
    return CheckboxListControlDtoFromJSONTyped(json, false);
}

export function CheckboxListControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckboxListControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': CheckboxListPropsDtoFromJSON(json['properties']),
        'type': json['type'],
        'relativeId': json['relativeId'],
        'parentId': json['parentId'],
        'name': json['name'],
    };
}

export function CheckboxListControlDtoToJSON(value?: CheckboxListControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': CheckboxListPropsDtoToJSON(value.properties),
        'type': value.type,
        'relativeId': value.relativeId,
        'parentId': value.parentId,
        'name': value.name,
    };
}

