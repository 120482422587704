import tw from 'twin.macro';
import LogoImage from '@assets/logo.png';
import CloseButton from './CloseButton';
import SignOutButton from './SignOutButton';

const Container = tw.header`bg-gray-800 text-white shadow p-2 flex flex-row items-center space-x-4`;
const Logo = tw.img`w-10 h-10`;
const TextContainer = tw.div`flex-grow flex flex-col`;
const Title = tw.h1`font-semibold text-lg leading-none`;
const Subtitle = tw.h2`leading-none text-xs`;

interface Props {
    subtitle?: string | undefined;
    showClose?: boolean | undefined;
    showSignOut?: boolean | undefined;
}

function Header({ subtitle, showClose, showSignOut }: Props) {
    return (
        <Container>
            <Logo src={LogoImage} />
            <TextContainer>
                <Title>ReVPD</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TextContainer>
            {showClose && <CloseButton />}
            {showSignOut && <SignOutButton />}
        </Container>
    );
}

Header.defaultProps = {
    subtitle: undefined,
    showClose: false,
    showSignOut: false,
};

export default Header;
