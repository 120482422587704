import ControlTree from '@components/ControlTree';
import DesignerSurface from '@components/DesignerSurface';
import PropertiesPane from '@components/PropertiesPane';
import ProjectExplorer from '@components/ProjectExplorer';
import useProject from '@hooks/useProject';
import tw, { styled } from 'twin.macro';
import { FormContextProvider } from '@hooks/useForm';
import Header from './Header';
import StatusBar from './StatusBar';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full max-w-full`;
const Viewport = styled.div(() => [tw`flex flex-grow flex-row`, 'max-height: calc(100% - 56px - 24px);']);
const SideContainer = tw.div`w-80 shadow max-h-full bg-white border-r border-gray-300 flex flex-col flex-shrink-0`;

export default function ProjectViewport() {
    const { project, activeFormId } = useProject();

    return (
        <FormContextProvider formId={activeFormId}>
            <Container>
                <Header subtitle={project?.name} showClose={!!project} />
                <Viewport>
                    <SideContainer>
                        <ProjectExplorer />
                        {activeFormId && <ControlTree />}
                    </SideContainer>
                    <DesignerSurface />
                    <PropertiesPane />
                </Viewport>
                <StatusBar />
            </Container>
        </FormContextProvider>
    );
}
