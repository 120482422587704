const FDOTokens = new Set([
    'idb_atr_dod',
    'idb_start_obj',
    'idb_end_obj',
    'idb_delete_obj',
    'idb_close_obj',
    'idb_exists',
    'idb_start_extraction',
    'idb_end_extraction',
    'idb_get_data',
    'idb_get_value',
    'idb_dod_failed',
    'idb_append_data',
    'idb_data',
    'idb_change_context',
    'idb_end_context',
    'idb_reset',
    'idb_get_string',
    'idb_cancel',
    'idb_set_context',
    'idb_atr_globalid',
    'idb_atr_length',
    'idb_atr_stamp',
    'idb_atr_offset',
    'idb_atr_type',
    'idb_atr_compressed',
    'idb_atr_encrypted',
    'idb_atr_expiration',
    'idb_atr_compress',
    'idb_use_default_icon',
    'idb_get_length',
    'idb_dod_progress_gauge',
    'idb_get_data_null_terminated',
    'idb_check_and_set_ftv',
    'idb_clear_ftv',
    'uni_void',
    'uni_start_stream',
    'uni_end_stream',
    'uni_abort_stream',
    'uni_start_large_atom',
    'uni_large_atom_segment',
    'uni_end_large_atom',
    'uni_sync_skip',
    'uni_start_loop',
    'uni_end_loop',
    'uni_use_last_atom_string',
    'uni_use_last_atom_value',
    'uni_save_result',
    'uni_data',
    'uni_wait_on',
    'uni_wait_off',
    'uni_start_stream_wait_on',
    'uni_wait_off_end_stream',
    'uni_invoke_no_context',
    'uni_invoke_local',
    'uni_get_result',
    'uni_next_atom_typed',
    'uni_start_typed_data',
    'uni_end_typed_data',
    'uni_force_processing',
    'uni_set_command_set',
    'uni_wait_clear',
    'uni_change_stream_id',
    'uni_diagnostic_msg',
    'uni_hold',
    'uni_invoke_local_preserve',
    'uni_invoke_local_later',
    'uni_convert_last_atom_string',
    'uni_break',
    'uni_single_step',
    'uni_convert_last_atom_data',
    'uni_get_first_stream',
    'uni_get_next_stream',
    'uni_get_stream_window',
    'uni_cancel_action',
    'uni_get_current_stream_id',
    'uni_set_data_length',
    'uni_use_last_atom_data',
    'uni_set_watchdog_interval',
    'uni_udo_complete',
    'uni_test_update',
    'uni_insert_stream',
    'uni_next_atom_mixed_data',
    'uni_start_mixed_data_mode',
    'uni_end_mixed_data_mode',
    'uni_transaction_id',
    'uni_result_code',
    'uni_command',
    'man_start_object',
    'man_start_sibling',
    'man_end_object',
    'man_close',
    'man_close_children',
    'man_do_magic_token_arg',
    'man_do_magic_response_id',
    'man_set_response_id',
    'man_set_context_response_id',
    'man_set_context_globalid',
    'man_set_context_relative',
    'man_set_context_index',
    'man_change_context_relative',
    'man_clear_relative',
    'man_clear_object',
    'man_use_default_title',
    'man_update_display',
    'man_update_woff_end_stream',
    'man_update_end_object',
    'man_append_data',
    'man_replace_data',
    'man_preset_gid',
    'man_preset_title',
    'man_place_cursor',
    'man_set_domain_type',
    'man_set_domain_info',
    'man_response_pop',
    'man_close_update',
    'man_end_context',
    'man_item_get',
    'man_item_set',
    'man_start_first',
    'man_do_edit_menu',
    'man_logging_command',
    'man_get_index_by_title',
    'man_start_alpha',
    'man_start_last',
    'man_insert_object_after',
    'man_cut_object',
    'man_copy_object',
    'man_paste_object',
    'man_is_rendered',
    'man_preset_relative',
    'man_insert_object_before',
    'man_make_focus',
    'man_get_top_window',
    'man_get_first_response_id',
    'man_get_next_response_id',
    'man_get_response_window',
    'man_get_request_window',
    'man_ignore_response',
    'man_get_first_window',
    'man_get_next_window',
    'man_is_response_pending',
    'man_is_response_ignored',
    'man_get_attribute',
    'man_set_item_type',
    'man_set_default_title',
    'man_get_child_count',
    'man_check_and_set_context_rid',
    'man_clear_file_name',
    'man_is_window_iconic',
    'man_post_update_gid',
    'man_end_data',
    'man_update_fonts',
    'man_enable_one_shot_timer',
    'man_enable_continuous_timer',
    'man_kill_timer',
    'man_force_update',
    'man_set_edit_position',
    'man_set_edit_position_to_end',
    'man_preset_authoring_form',
    'man_add_date_time',
    'man_start_title',
    'man_add_title_text',
    'man_add_title_tab',
    'man_add_title_date_time',
    'man_end_title',
    'man_preset_url',
    'man_get_dropped_url',
    'man_force_old_style_dod',
    'man_preset_tag',
    'man_build_font_list',
    'man_spell_check',
    'man_obj_stack_push',
    'man_obj_stack_pop',
    'man_display_popup_menu',
    'man_wm_close',
    'man_set_append_secure_data',
    'man_append_secure_data',
    'man_start_ip_session',
    'man_end_ip_session',
    'man_build_savemail_menu',
    'man_build_favorites_menu',
    'man_get_display_characteristics',
    'mat_bool_vertical_scroll',
    'mat_bool_disabled',
    'mat_bool_default',
    'mat_bool_resize_vertical',
    'mat_bool_resize_horizontal',
    'mat_bool_modal',
    'mat_bool_invisible',
    'mat_bool_hidden',
    'mat_orientation',
    'mat_capacity',
    'mat_font_sis',
    'mat_relative_tag',
    'mat_object_id',
    'mat_art_id',
    'mat_width',
    'mat_height',
    'mat_title_pos',
    'mat_ruler',
    'mat_object_color',
    'mat_vertical_spacing',
    'mat_iconify_as',
    'mat_plus_group',
    'mat_size',
    'mat_title',
    'mat_title_width',
    'mat_bool_list_icons',
    'mat_bool_graphic_view',
    'mat_bool_palette',
    'mat_bool_horizontal_scroll',
    'mat_bool_force_scroll',
    'mat_bool_protected_input',
    'mat_bool_force_no_scroll',
    'mat_bool_non_closeable',
    'mat_bool_exportable',
    'mat_bool_importable',
    'mat_bool_writeable',
    'mat_bool_floating',
    'mat_bool_contiguous',
    'mat_bool_menu',
    'mat_bool_default_send',
    'mat_bool_double_space',
    'mat_object_type',
    'mat_vert_scroll_capacity',
    'mat_horz_scroll_capacity',
    'mat_font_id',
    'mat_font_size',
    'mat_font_style',
    'mat_value',
    'mat_border',
    'mat_command_key',
    'mat_dirty_query',
    'mat_managed_by',
    'mat_validation',
    'mat_horizontal_spacing',
    'mat_style_id',
    'mat_minimum',
    'mat_maximum',
    'mat_horz_scroll_value',
    'mat_vert_scroll_value',
    'mat_bool_invert',
    'mat_position',
    'mat_log_object',
    'mat_paragraph',
    'mat_scroll_threshold',
    'mat_shortcut_key',
    'mat_sort_order',
    'mat_unfocused',
    'mat_bool_permanent',
    'mat_bool_ignore',
    'mat_increment',
    'mat_bool_no_border',
    'mat_bool_modified',
    'mat_link_content_to_rid',
    'mat_bool_precise',
    'mat_precise_width',
    'mat_precise_height',
    'mat_precise_x',
    'mat_precise_y',
    'mat_color_face',
    'mat_color_text',
    'mat_color_top_edge',
    'mat_color_bottom_edge',
    'mat_bool_gradual_shadow',
    'mat_frame_style',
    'mat_trigger_style',
    'mat_color_selected',
    'mat_color_text_shadow',
    'mat_timer_event',
    'mat_timer_duration',
    'mat_bool_background_pic',
    'mat_bool_background_flood',
    'mat_color_frame_hilight',
    'mat_color_frame_shadow',
    'mat_art_frame',
    'mat_art_animation_rate',
    'mat_art_animation_seq',
    'mat_bool_repeat_animation',
    'mat_bool_background_tile',
    'mat_context_help',
    'mat_drop_data_type',
    'mat_field_script',
    'mat_bool_first_script',
    'mat_bool_list_allow_entry',
    'mat_url',
    'mat_bool_expand_to_fit',
    'mat_sink',
    'mat_bool_sane',
    'mat_bool_url_sink',
    'mat_bool_drop_at_top',
    'mat_form_icon',
    'mat_content_tag',
    'mat_bool_language_popup',
    'mat_language_sensitive',
    'mat_bool_palette_art',
    'mat_set_dflt_extract_type',
    'mat_art_hint_width',
    'mat_art_hint_height',
    'mat_art_hint_title',
    'mat_art_hint_placeholder_id',
    'mat_art_hint_title_x',
    'mat_bool_tool_group',
    'mat_art_hint_title_y',
    'mat_art_hint_title_font_size',
    'mat_art_hint_title_font_id',
    'mat_art_hint_title_font_style',
    'mat_bool_small_icon',
    'mat_art_hint_select_placeholder',
    'mat_object_index',
    'mat_bool_encode_unicode',
    'mat_text_on_picture_pos',
    'mat_secure_form',
    'mat_fp_compose_mail',
    'mat_fp_send_im',
    'mat_fp_save_to_fp',
    'mat_bool_page_control',
    'mat_bool_spinner',
    'mat_bool_detached',
    'mat_bool_customizable',
    'mat_bool_detachable',
    'mat_bool_dock_horizontal',
    'mat_bool_dock_vertical',
    'mat_bool_children_removable',
    'mat_bool_children_movable',
    'mat_bool_child_removable',
    'mat_bool_child_movable',
    'mat_bool_child_line_feed',
    'mat_title_append_screen_name',
    'mat_url_list',
    'mat_tab_set_cur_sel',
    'mat_tab_get_cur_sel',
    'mat_bool_shared_style',
    'mat_bool_active_offline',
    'mat_bool_active_online',
    'mat_bool_inactive_for_guest',
    'mat_bool_dropdown_button',
    'mat_bool_popup_menu',
    'mat_bool_ignore_url_list',
    'mat_popup_relative_id',
    'mat_popup_pfc_path',
    'mat_bool_draw_focus',
    'mat_url_next',
    'mat_url_prev',
    'mat_url_parent',
    'mat_bool_loggable',
    'mat_secure_field',
    'mat_bool_hide_url',
    'mat_bool_auto_closeable',
    'mat_bool_signoff_menu',
    'mat_bool_ignore_url',
    'mat_bool_savable_to_pfc',
    'mat_bool_static_url',
    'mat_intl_font_sis',
    'ccl_dial',
    'ccl_hang_up',
    'ccl_install_modem_driver',
    'ccl_update_locality',
    'ccl_get_locality',
    'ccl_new_location',
    'ccl_translate_com_port',
    'ccl_translate_baud_rate',
    'ccl_translate_network',
    'ccl_translate_locality',
    'ccl_check_hang_up',
    'ccl_is_modem',
    'ccl_add_net_choices',
    'ccl_list_set_net',
    'ccl_list_get_net',
    'ccl_reload_networks',
    'ccl_enum_com_devices',
    'ccl_cancel_enum_devices',
    'var_number_save',
    'var_number_set',
    'var_number_set_from_atom',
    'var_number_get',
    'var_number_set_id_one_value',
    'var_string_set',
    'var_string_null',
    'var_string_save',
    'var_string_set_from_atom',
    'var_string_get',
    'var_string_set_id_one_value',
    'var_data_set',
    'var_data_zero',
    'var_data_save',
    'var_data_set_from_atom',
    'var_data_get',
    'var_data_set_id_one_value',
    'var_lookup_by_id',
    'var_numa_zero',
    'var_numa_ones',
    'var_numa_increment',
    'var_numa_decrement',
    'var_number_increment_save',
    'var_number_decrement_save',
    'var_number_zero_save',
    'var_number_ones_save',
    'var_number_add',
    'var_number_sub',
    'var_number_mul',
    'var_number_div',
    'var_number_anding',
    'var_number_oring',
    'var_number_shl',
    'var_number_shr',
    'var_number_copy_between_regs',
    'var_number_swap_between_regs',
    'var_number_clear_id',
    'var_number_increment',
    'var_number_decrement',
    'var_number_zero',
    'var_number_ones',
    'var_string_copy_stra_to_strb',
    'var_string_copy_strb_to_stra',
    'var_string_copy_between_regs',
    'var_string_swap_between_regs',
    'var_string_clear_id',
    'var_string_trim_spaces',
    'var_string_trim_spaces_safely',
    'var_data_copy_data_to_datb',
    'var_data_copy_datb_to_data',
    'var_data_copy_between_regs',
    'var_data_swap_between_regs',
    'var_data_clear_id',
    'var_reset',
    'var_random_number',
    'var_string_concatenate_regs',
    'var_check_string_type_and_convert',
    'var_get_string_convert_len',
    'p3_start',
    'p3_stop',
    'p3_write',
    'p3_interleaved_mode',
    'p3_debug_disable_outbound',
    'p3_debug_get_outbound_state',
    'async_exit',
    'async_password',
    'async_guest_password',
    'async_exit_damnit',
    'async_online',
    'async_offline',
    'async_error_box',
    'async_alert',
    'async_display_netnews',
    'async_go_netnews',
    'async_moreinfo_netnews',
    'async_playsound',
    'async_exit_aux',
    'async_exec_help',
    'async_exec_context_help',
    'async_play_sound_dammit',
    'async_exec_help_file',
    'async_force_off',
    'async_send_clientstatus',
    'async_get_stat_count',
    'async_extract_stats',
    'async_stat_collection_state',
    'async_clear_stats',
    'async_stat_record',
    'async_get_alert_result',
    'async_exec_app',
    'async_screen_name_changed',
    'async_is_known_subaccount',
    'async_dump_diag',
    'async_get_screen_name',
    'async_sign_on',
    'async_alert_start',
    'async_alert_add_text',
    'async_alert_add_date_time',
    'async_alert_end',
    'async_invoke_timezone_pref',
    'async_invoke_language_pref',
    'async_set_screen_name',
    'async_auto_launch',
    'async_launcher_name',
    'async_is_client_32bit',
    'async_display_errors',
    'async_is_guest',
    'async_relogon_init',
    'async_relogon',
    'async_storename',
    'async_storepassword',
    'async_signoff',
    'async_is_current_screenname',
    'async_logout',
    'async_check_and_invoke_driveway',
    'async_record_error',
    'async_system_usage',
    'async_replace_pref',
    'async_bool_diag_on',
    'xfer_abort',
    'xfer_show_file_description',
    'xfer_show_file_status',
    'xfer_start_download',
    'xfer_toggle_signoff',
    'xfer_finish_later',
    'xfer_decompress_file',
    'xfer_set_rle_flag',
    'xfer_clear_rle_flag',
    'xfer_refresh_prefs',
    'xfer_bool_in_progress',
    'xfer_invoke_dl_manager',
    'xfer_delete_spool_file',
    'xfer_start_object',
    'xfer_atr_request_id',
    'xfer_atr_file_size',
    'xfer_atr_title',
    'xfer_atr_file_name',
    'xfer_end_object',
    'xfer_invoke_archive',
    'xfer_atr_library',
    'xfer_bool_mail',
    'xfer_atr_create_date',
    'xfer_batch_download',
    'de_set_extraction_type',
    'de_data',
    'de_start_extraction',
    'de_set_data_type',
    'de_set_variable_id',
    'de_set_text_column',
    'de_get_data',
    'de_end_extraction',
    'de_get_data_value',
    'de_get_data_pointer',
    'de_ez_send_form',
    'de_custom_data',
    'de_ez_send_list_text',
    'de_ez_send_list_index',
    'de_ez_send_field',
    'de_validate',
    'if_numa_false_then',
    'if_numb_true_then',
    'if_numb_false_then',
    'if_numa_eq_one_then',
    'if_numa_gt_one_then',
    'if_numb_eq_one_then',
    'if_numb_gt_one_then',
    'if_stra_eq_null_then',
    'if_strb_eq_null_then',
    'if_stra_neq_null_then',
    'if_strb_neq_null_then',
    'if_data_eq_zero_then',
    'if_datb_eq_zero_then',
    'if_data_neq_zero_then',
    'if_datb_neq_zero_then',
    'if_free_area_then',
    'if_test_index_true_then',
    'if_test_index_false_then',
    'if_last_return_true_then',
    'if_last_return_false_then',
    'if_last_return_true_exit',
    'if_last_return_false_exit',
    'if_paid_area_then',
    'if_numa_eq_numb_then',
    'if_numa_neq_numb_then',
    'if_numa_gt_numb_then',
    'if_numa_gte_numb_then',
    'if_numa_lt_numb_then',
    'if_numa_lte_numb_then',
    'if_numa_and_numb_then',
    'if_numa_or_numb_then',
    'if_numa_true_then',
    'if_stra_eq_strb_then',
    'if_stra_neq_strb_then',
    'if_stra_in_strb_prefix_then',
    'if_strb_in_stra_prefix_then',
    'if_data_eq_datb_then',
    'if_data_neq_datb_then',
    'if_data_in_datb_prefix_then',
    'if_datb_in_data_prefix_then',
    'if_online_then',
    'if_offline_then',
    'if_guest_then',
    'if_owner_then',
    'if_newuser_then',
    'if_am_then',
    'if_pm_then',
    'if_debug_true_then',
    'if_debug_false_then',
    'act_set_criterion',
    'act_do_action',
    'act_set_inheritance',
    'act_replace_action',
    'act_replace_select_action',
    'act_sound_beep',
    'act_modify_action',
    'act_set_test_index',
    'act_clr_test_index',
    'act_set_index',
    'act_append_action',
    'act_prepend_action',
    'act_change_action',
    'act_append_select_action',
    'act_prepend_select_action',
    'act_change_select_action',
    'act_copy_action_to_reg',
    'act_replace_action_from_reg',
    'act_append_action_from_reg',
    'act_prepend_action_from_reg',
    'act_change_action_from_reg',
    'act_set_action_in_reg',
    'act_interpret_packet',
    'act_set_db_length',
    'act_get_db_record',
    'act_set_db_id',
    'act_set_db_record',
    'act_set_guest_flag',
    'act_set_newuser_flag',
    'act_set_db_offset',
    'act_get_db_value',
    'act_format_quote',
    'act_replace_popup_menu_action',
    'buf_start_buffer',
    'buf_add_atom_pointer',
    'buf_add_atom_data',
    'buf_add_pointer',
    'buf_add_data',
    'buf_add_token',
    'buf_set_token',
    'buf_clear_buffer',
    'buf_close_buffer',
    'buf_save_buffer_to_db',
    'buf_load_buffer_from_db',
    'buf_send_buffer_to_host',
    'buf_set_flags',
    'buf_add_atom_pointer_filter',
    'buf_use_buffer',
    'buf_add_atom_data_trim',
    'buf_add_data_trim',
    'buf_set_data_atom',
    'buf_destroy_buffer',
    'buf_add_string_raw',
    'buf_add_data_raw',
    'buf_set_protocol',
    'buf_get_flags',
    'buf_get_data_atom',
    'buf_get_protocol',
    'buf_start_scrbuf',
    'buf_end_scrbuf',
    'buf_add_string_to_scrbuf',
    'buf_add_value_to_scrbuf',
    'buf_get_scrbuf',
    'buf_set_callback',
    'buf_debug_dump_buffers',
    'buf_restart_buffer',
    'buf_add_atom_typed_data',
    'buf_add_atom_pointer_flip',
    'buf_add_atom_data_flip',
    'buf_add_data_flip',
    'buf_set_security_tokens',
    'buf_meter_abort',
    'fm_start',
    'fm_end',
    'fm_item_type',
    'fm_item_set',
    'fm_item_get',
    'fm_broadcast',
    'fm_handle_error',
    'fm_add_file_type',
    'fm_delete_file_type',
    'fm_add_file_type_mask',
    'fm_delete_file_type_mask',
    'fm_register',
    'fm_unregister',
    'fm_add_type_to_group',
    'fm_delete_type_from_group',
    'fm_find_file_type',
    'fm_dialog_get_file',
    'fm_dialog_put_file',
    'fm_dialog_get_type',
    'fm_dialog_print',
    'fm_create_file',
    'fm_open_file',
    'fm_delete_file',
    'fm_rename_file',
    'fm_check_disk_space',
    'fm_close_file',
    'fm_append_data',
    'fm_position_file',
    'fm_position_eof',
    'fm_send_contents_to_buffer',
    'fm_dump',
    'fm_get_first_file',
    'fm_get_next_file',
    'fm_ini_read_data',
    'fm_ini_write_data',
    'fm_set_relative_path',
    'fm_read_file',
    'fm_unregister_list',
    'fm_flush_file',
    'fm_ini_get_section',
    'fm_ini_get_next_line',
    'fm_copy_file',
    'fm_keyword_okay',
    'fm_ini_delete_file',
    'fm_dialog_init_get',
    'fm_dialog_end_get',
    'fm_dialog_cancel_get',
    'fm_edit_attachment_command',
    'lm_open_window',
    'lm_close_window',
    'lm_user_delete_item',
    'lm_start_list',
    'lm_end_list',
    'lm_start_list_entry',
    'lm_end_list_entry',
    'lm_attr_list_type',
    'lm_attr_list_form',
    'lm_display_list',
    'lm_attr_list_entry_id',
    'lm_get_list_entry_title',
    'lm_get_list_entry_id',
    'lm_retrieve_item',
    'lm_render_item',
    'lm_table_clear',
    'lm_table_use_table',
    'lm_table_use_key',
    'lm_table_get_item',
    'lm_table_set_item',
    'lm_table_delete_item',
    'lm_table_get_first_key',
    'lm_table_get_next_key',
    'lm_get_selected_item',
    'lm_get_selected_item_id',
    'lm_attr_list_entry_type',
    'lm_get_num_selections',
    'lm_get_first_selection',
    'lm_get_next_selection',
    'lm_check_item_id',
    'lm_uncheck_item_id',
    'lm_delete_item_id',
    'lm_add_entry_text',
    'lm_add_entry_tab',
    'lm_add_entry_date_time',
    'lm_table_get_item_value',
    'lm_table_set_item_value',
    'lm_table_encrypt_table',
    'cm_mark_tool_invalid',
    'cm_set_byte_count',
    'cm_force_off',
    'cm_inquire_add_tool',
    'cm_inquire',
    'cm_inquire_all',
    'cm_clear_list',
    'cm_load_tool',
    'cm_kill_tool',
    'cm_bounce_tool',
    'cm_version',
    'cm_check_tool',
    'cm_cancel_job',
    'cm_mark_proto_invalid',
    'cm_mark_atom_invalid',
    'cm_mark_token_invalid',
    'cm_use_proto',
    'cm_start_block',
    'cm_end_block',
    'cm_set_cancel_record',
    'cm_set_to_restart',
    'cm_cancel_held_streams',
    'cm_check_wid',
    'cm_get_tid_from_wid',
    'cm_start_required_tool_list',
    'cm_end_required_tool_list',
    'cm_add_required_tool',
    'cm_is_tool_loaded',
    'cm_disk_space_needed',
    'cm_set_splash_record',
    'cm_update_notify_token',
    'cm_tod_name',
    'cm_set_request_record',
    'cm_mark_widget_invalid',
    'cm_accept_update',
    'cm_reject_update',
    'cm_get_disk_space_required',
    'cm_get_time_estimate',
    'cm_is_restart_necessary',
    'cm_show_tools',
    'cm_inquire_changed',
    'cm_trigger_tod',
    'cm_tool_verify_set_tid',
    'cm_tool_verify',
    'cm_set_cleanup_record',
    'cm_host_lib_index',
    'cm_lib_start_date',
    'cm_lib_end_date',
    'cm_version_info',
    'cm_missing_protocol',
    'cm_missing_atom',
    'cm_missing_tool',
    'cm_missing_token',
    'cm_job_id',
    'cm_packet_num',
    'cm_tool_id',
    'cm_widget_id',
    'cm_host_update_ack',
    'cm_tb_set_name',
    'cm_tb_test_ver',
    'cm_tb_get_ver',
    'cm_tb_get_path',
    'cm_tb_test_name_loaded',
    'cm_tb_clear_name',
    'cm_tb_test_name_exists',
    'cm_tb_get_windows_dir',
    'cm_tb_win_getregistrykey',
    'cm_tb_get_physical_memsize',
    'chat_room_open',
    'chat_add_user',
    'chat_end_object',
    'chat_remove_user',
    'chat_show_arrival',
    'chat_message',
    'chat_refresh_pref',
    'chat_host',
    'chat_name_view',
    'chat_new_room_open',
    'chat_screen_name',
    'chat_new_message',
    'chat_user_enter_thru_list',
    'chat_user_exit',
    'chat_log_room',
    'chat_link_view_to_list',
    'chat_host_message',
    'chat_link_count_to_list',
    'chat_set_view_log_priority',
    'chat_log_path_rid',
    'chat_close_room',
    'chat_bool_announce',
    'chat_is_chat_view',
    'chat_screen_name_color',
    'chat_screen_name_back_color',
    'chat_screen_name_font_size',
    'chat_enum_rooms_in_list',
    'chat_is_new_chat_room',
    'chat_is_room_being_logged',
    'chat_get_log_file_name',
    'chat_set_users_list_icon',
    'chat_user_enter',
    'chat_start_message',
    'chat_add_message_text',
    'chat_add_message_date_time',
    'chat_end_message',
    'sm_do_list_action',
    'sm_end_object_with_action',
    'sm_end_object_with_var',
    'sm_send_k1',
    'sm_send_er',
    'sm_send_mr',
    'sm_send_mf',
    'sm_send_bm',
    'sm_send_bn',
    'sm_send_f1',
    'sm_check_domain',
    'sm_set_domain',
    'sm_send_token_arg',
    'sm_p_send_token_arg',
    'sm_mp_send_token_arg',
    'sm_send_token_raw',
    'sm_m_send_token_raw',
    'sm_send_window_var',
    'sm_m_send_window_var',
    'sm_p_send_window_var',
    'sm_mp_send_window_var',
    'sm_send_list_var',
    'sm_m_send_list_var',
    'sm_m_send_token_arg',
    'sm_idb_get_data',
    'sm_set_object_domain',
    'sm_send_free_k1',
    'sm_send_paid_k1',
    'sm_send_free_f1',
    'sm_send_paid_f1',
    'sm_set_plus_group',
    'sm_check_plus_group',
    'sm_enter_paid',
    'sm_enter_free',
    'mt_manage_tools',
    'mt_bounce_tool',
    'mt_kill_tool',
    'mt_display_tool_info',
    'mt_load_tool',
    'mt_browse_tree',
    'mt_flip_value',
    'mt_test_crash',
    'mt_gid_to_string',
    'mt_string_to_gid',
    'mt_waolsock_debug',
    'mt_break',
    'mt_set_spy_mode',
    'mt_set_timeout_mode',
    'mt_start_atomdebug',
    'mt_end_atomdebug',
    'mt_open_diag',
    'mt_delete_art_in_top_form',
    'mt_hilight_groups_in_top_form',
    'mt_play_text',
    'mt_play_binary',
    'mt_database',
    'mt_start_edit_text',
    'mt_super_snoop',
    'ad_add',
    'ad_store',
    'ad_modify',
    'ad_set_caller',
    'ad_to',
    'ad_cc',
    'mmi_open_file',
    'mmi_atr_toolbar',
    'mmi_atr_rid_play',
    'mmi_atr_rid_pause',
    'mmi_atr_rid_stop',
    'mmi_atr_rid_rewind',
    'mmi_play',
    'mmi_pause',
    'mmi_stop',
    'mmi_rewind',
    'mmi_start_data',
    'mmi_append_data',
    'mmi_end_data',
    'mmi_atr_type',
    'mmi_db_id',
    'mmi_open_file_relative',
    'mmi_atr_rid_counter_digit',
    'mmi_atr_loop',
    'mmi_manage',
    'mmi_refresh_prefs',
    'mmi_set_attr_priority',
    'mmi_get_attr_priority',
    'mmi_xfer_data',
    'mip_start_message',
    'mip_header_complete',
    'mip_end_message',
    'mip_header_accepted',
    'mip_message_accepted',
    'mip_error',
    'mip_abort',
    'mip_start_block',
    'mip_end_block',
    'mip_addressee',
    'mip_subject',
    'mip_text',
    'mip_data',
    'mip_message_id',
    'mip_author',
    'mip_message_date',
    'mip_message_datestr',
    'mip_forward',
    'mip_attr_addressee_type',
    'mip_attr_data_type',
    'mip_attr_file_name',
    'mip_attr_file_size',
    'mip_attr_file_type',
    'mip_attr_file_create_date',
    'mip_attr_message_type',
    'mip_bool_return_receipt',
    'mip_attr_list_type',
    'mip_addressee_comment',
    'mip_action_command',
    'mip_set_calling_form_id',
    'mip_end_of_data',
    'mip_scheduler_mode',
    'mip_flip_it',
    'mip_edit_outbox',
    'mip_preset_form_title',
    'mip_get_active_name_count',
    'mip_is_name_active',
    'mip_set_name_active',
    'mip_clear_active_names',
    'mip_set_forward_ref',
    'mip_attr_folder',
    'mip_abort_all',
    'mip_preset_message_id',
    'mip_change_message_status',
    'mip_attr_encoding_format',
    'mip_bool_everyone_invisible',
    'mip_reply_to',
    'mip_do_not_reply_to',
    'mip_read_mail',
    'mip_bool_will_ack',
    'mip_bool_inet_quoting',
    'mip_start_errors',
    'mip_end_errors',
    'mip_display_mail_form',
    'mip_delete_online_lists',
    'mip_get_next_typed',
    'mip_get_prev_typed',
    'mip_spell_check_send_now',
    'mip_spell_check_send_later',
    'image_set_file_name',
    'image_attr_rotate',
    'image_flip_horizontal',
    'image_flip_vertical',
    'image_invert',
    'image_attr_sharpness',
    'image_attr_brightness',
    'image_attr_contrast',
    'image_attr_image_type',
    'image_attr_use_suggested_size',
    'image_attr_aspect_ratio',
    'image_attr_dynamic_display',
    'image_get_attr',
    'image_invoke_editor',
    'image_make_gray_scale',
    'image_revert',
    'image_set_to_orginal_size',
    'image_cancel_modal',
    'image_attr_extraction_format',
    'image_attr_comp_quality',
    'image_crop_image',
    'image_attr_hotspot',
    'image_attr_hotspot_action_id',
    'image_delete_hotspot',
    'image_attr_hotspot_grid',
    'image_attr_image_cropable',
    'image_attr_draw_hotspot_grid',
    'image_attr_hotspot_def_action',
    'image_attr_show_hotspot',
    'image_attr_fit_to_view',
    'image_attr_background_blend',
    'image_attr_hotspot_type',
    'image_attr_custom_hilight',
    'image_attr_hotspot_offset',
    'image_attr_hilight_type',
    'image_attr_custom_offset',
    'image_attr_file_id',
    'image_attr_thumbnail',
    'image_attr_save_orig_size',
    'image_open_file_relative',
    'image_db_id',
    'image_attr_scale_display',
    'image_attr_scale_best_fit',
    'imgxfer_set_context',
    'imgxfer_end_context',
    'imgxfer_set_rid',
    'imgxfer_set_gid',
    'imgxfer_attr_wait_active',
    'imgxfer_is_spoolfile',
    'imgxfer_get_spoolfile_name',
    'chart_type',
    'chart_xmax',
    'chart_ymax',
    'chart_xmin',
    'chart_ymin',
    'chart_scale',
    'chart_set_stripe_top',
    'chart_bottom_label',
    'chart_left_label',
    'chart_title',
    'chart_checkpoint',
    'chart_request_interval',
    'chart_start_dataset',
    'chart_end_dataset',
    'chart_set_dataset_context',
    'chart_end_dataset_context',
    'chart_set_dataset_shape',
    'chart_right_label',
    'chart_set_dataset_color',
    'chart_set_bar_init_value',
    'chart_set_point_context',
    'chart_set_point_value',
    'chart_set_constant',
    'chart_set_xvalue',
    'chart_set_stripe_bottom',
    'chart_set_ylegend_context',
    'chart_set_3d_x_rotation',
    'chart_set_3d_y_rotation',
    'chart_set_marker_size',
    'chart_set_dataset_bkcolor',
    'chart_set_point_type',
    'chart_set_decimals',
    'chart_set_constant_bkcolor',
    'chart_set_constant_color',
    'chart_set_constant_width',
    'chart_set_constant_label',
    'chart_set_pattern_scheme',
    'chart_set_line_bkcolor',
    'chart_set_line_color',
    'chart_set_line_style',
    'chart_set_line_width',
    'chart_set_chart_bkcolor',
    'chart_set_bkcolor',
    'chart_title_font_sis',
    'chart_right_font_sis',
    'chart_left_font_sis',
    'chart_bottom_font_sis',
    'chart_add_dataset_legend',
    'chart_bool_stacked',
    'chart_bool_horiz_grid',
    'chart_bool_vert_grid',
    'chart_bool_horiz_bar',
    'chart_bool_show_tools',
    'chart_bool_3d_view',
    'chart_bool_show_palette',
    'chart_bool_show_patterns',
    'chart_bool_show_menu',
    'chart_bool_show_legend',
    'chart_bool_bars_together',
    'chart_bool_show_points',
    'chart_bool_show_zero',
    'chart_bool_each_bar',
    'chart_bool_cluster',
    'chart_bool_showdata',
    'chart_bool_dlggray',
    'chart_add_ylegend_text',
    'chart_add_xlegend_text',
    'chart_set_constant_context',
    'chart_set_stripe_color',
    'chart_set_stripe_context',
    'chart_start_stripe',
    'chart_end_stripe',
    'chart_add_key_legend_text',
    'chart_set_ylegend_gap',
    'chart_start_constant',
    'chart_end_constant',
    'chart_set_constant_style',
    'chart_get_attribute',
    'chart_set_atom_decimal_places',
    'chart_xlegend_font_sis',
    'chart_ylegend_font_sis',
    'chart_constant_font_sis',
    'chart_legend_font_sis',
    'chart_set_response_token',
    'chart_atr_num_datasets',
    'chart_dataset_chart_type',
    'chart_set_close_token',
    'chart_set_close_arg',
    'chart_set_tools',
    'chart_set_gallery',
    'chart_set_pattern',
    'chart_set_toolbar_pos',
    'chart_set_legend_pos',
    'chart_set_dataset_legend_pos',
    'chart_bool_show_ds_legend',
    'chart_mac_set_attr',
    'chart_bool_legend_2level',
    'chart_bool_legend_vertical',
    'chart_manage',
    'chart_set_y_inc_gap',
    'chart_set_margins',
    'chart_add_date_legend_text',
    'vrm_start_form',
    'vrm_send_form',
    'vrm_save_form',
    'vrm_action_command',
    'vrm_start_html_form',
    'vrm_form_up',
    'vrm_html_get_wiz',
    'vrm_html_start_wiz',
    'vrm_html_finish_wiz',
    'vrm_am_get_spawn_path',
    'vrm_html_get_spawn_path',
    'vrm_html_get_spawn_file',
    'vrm_html_form_up',
    'vrm_aat_art_start_result',
    'vrm_aat_art_end_result',
    'vrm_aat_art_gid',
    'vrm_aat_art_name',
    'vrm_aat_art_return_code',
    'vrm_aat_art_image_id',
    'vrm_aat_art_size_x',
    'vrm_aat_art_size_y',
    'vrm_aat_art_length',
    'vrm_aat_art_modified_by',
    'vrm_aat_art_modified_date',
    'vrm_aat_art_format',
    'vrm_aat_art_type',
    'vrm_aat_art_plus_group',
    'vrm_aat_art_dest_gid',
    'vrm_aat_art_trigger_form_id',
    'vrm_aat_art_exit_free_pay_curtain',
    'vrm_aat_art_repository',
    'vrm_set_context',
    'vrm_aat_query_start_result',
    'vrm_aat_query_end_result',
    'vrm_aat_art_exp_date',
    'vrm_aat_set_obj_context',
    'vrm_aat_start_record',
    'vrm_aat_end_record',
    'vrm_aat_art_ownership',
    'vrm_generic_query',
    'vrm_html_preset_miniicon',
    'vrm_html_preset_editform',
    'vrm_choose_art_id',
    'vrm_bool_object_needs_update',
    'vrm_finalize_objects',
    'vrm_start_form_info',
    'vrm_html_set_domain',
    'vrm_rainman_host_min',
    'vrm_start_rainman',
    'vrm_end_rainman',
    'vrm_rainman_error_code',
    'vrm_rainman_data',
    'www_load_url',
    'www_go_back',
    'www_go_forward',
    'www_reload',
    'www_stop',
    'www_get_current_url',
    'www_get_current_link',
    'www_get_parts_complete',
    'www_can_go_back',
    'www_can_go_forward',
    'www_get_current_title',
    'www_browse',
    'www_manage',
    'www_refresh_prefs',
    'www_purge_cache',
    'www_get_status_msg',
    'www_bool_suppress_errors',
    'www_invoke_options',
    'www_get_current_full_url',
    'morg_prompt_create_folder',
    'morg_delete_current_object',
    'morg_view_path',
    'morg_view_id',
    'morg_obj_count_children',
    'morg_view_include_root',
    'morg_prompt_rename_object',
    'morg_view_select_object',
    'morg_view_set_to_spawn_path',
    'morg_obj_destroy',
    'morg_manage',
    'morg_view_highlight_full_line',
    'morg_view_allow_multi_select',
    'morg_view_search',
    'morg_obj_destroy_children',
    'morg_obj_set_context',
    'morg_obj_end_context',
    'morg_obj_start_obj',
    'morg_obj_end_obj',
    'morg_atr_title',
    'morg_atr_url',
    'morg_flag_open',
    'morg_obj_start_folder',
    'morg_obj_start_bookmark',
    'morg_view_has_mult_selections',
    'morg_obj_set_context_selection',
    'morg_obj_can_delete',
    'morg_obj_can_rename',
    'morg_obj_get_class',
    'morg_jump_bookmark',
    'morg_add_bookmark',
    'morg_set_bookmark_url',
    'morg_modify_bookmark',
    'morg_edit_bookmark',
    'morg_finish_jump_bookmark',
    'morg_set_bookmark_close_form',
    'morg_view_allow_delete_key',
    'morg_pfc_compress_database',
    'morg_jump_url',
    'morg_pfc_cancel_compression',
    'morg_pfc_check_db',
    'morg_pfc_check_fragment',
    'morg_obj_can_create',
    'morg_act_on_selected',
    'morg_set_tab_list',
    'morg_obj_get_name',
    'morg_obj_set_context_by_index',
    'morg_save_folder',
    'morg_view_hide_folder',
    'morg_view_show_folder',
    'morg_set_selection',
    'morg_swap_screenname',
    'morg_replace_screenname',
    'morg_add_folder',
    'exapi_list_start_context',
    'exapi_list_set_stream',
    'exapi_list_attr_set_style',
    'exapi_list_attr_set_title',
    'exapi_list_attr_set_gid',
    'exapi_list_item_start',
    'exapi_list_item_set_sreg',
    'exapi_list_item_set_str',
    'exapi_list_item_set_nreg',
    'exapi_list_item_set_num',
    'exapi_list_item_set_freg',
    'exapi_list_item_set_flag',
    'exapi_list_item_set_stream',
    'exapi_list_item_set_address',
    'exapi_list_item_set_port',
    'exapi_list_item_end',
    'exapi_list_end_context',
    'exapi_manage',
    'exapi_version_verify',
    'exapi_list_clear',
    'exapi_list_item_delete',
    'exapi_is_valid_version',
    'exapi_enter_anteroom',
    'exapi_leave_anteroom',
    'exapi_set_selection_item',
    'exapi_gain_focus',
    'exapi_get_serial_info',
    'exapi_set_selection_list',
    'exapi_get_instance_info',
    'exapi_get_participant_list',
    'exapi_get_participant_info',
    'exapi_begin_versioning',
    'exapi_component_begin',
    'exapi_component_file_id',
    'exapi_component_file_size',
    'exapi_component_file_date',
    'exapi_component_file_offset',
    'exapi_component_file_library',
    'exapi_component_sysfile',
    'exapi_component_sysver',
    'exapi_component_end',
    'exapi_end_versioning',
    'exapi_delete_context',
    'exapi_begin_install',
    'exapi_set_default_folder_name',
    'exapi_set_required_space',
    'exapi_set_config_style',
    'exapi_set_program_name',
    'exapi_set_app_name',
    'exapi_end_install',
    'exapi_get_version_info',
    'exapi_launch_application',
    'exapi_component_total_size',
    'exapi_set_setup_app_name',
    'exapi_component_setup_app',
    'exapi_component_set_flags',
    'exapi_set_flags',
    'exapi_component_set_id',
    'exapi_move_shared_dir',
    'exapi_set_current_address',
    'exapi_uninstall_app',
    'exapi_set_uninstaller_name',
    'exapi_app_terminate',
    'exapi_set_session_id',
    'exapi_set_session_flags',
    'exapi_set_platform',
    'exapi_perform_versioning',
    'exapi_set_message_handler',
    'exapi_set_idle_interval',
    'exapi_idle',
    'rich_manage',
    'rich_edit_action',
    'rich_add_url',
    'rich_set_color_item',
    'rich_bool_read_only',
    'rich_open_file',
    'rich_text_color',
    'rich_font_justify',
    'rich_background_color',
    'rich_font_size',
    'rich_font_attribute',
    'rich_window_color',
    'rich_get_text_alignment',
    'rich_get_text_style',
    'rich_set_static',
    'rich_color_dialog',
    'rich_view_text_color',
    'rich_view_text_background_color',
    'rich_view_background_color',
    'rich_font_select_face',
    'rich_font_select_size',
    'rich_drop_picture',
    'rich_drop_link',
    'rich_get_text_font_size',
    'rich_get_font',
    'rich_allow_justify',
    'rich_allow_fontsize',
    'rich_allow_backcolor',
    'rich_allow_fontwindow',
    'rich_allow_embeds',
    'rich_allow_textfile',
    'rich_ignore_tabs',
    'rich_allow_attributes',
    'rich_image_max',
    'rich_drop_back_picture',
    'rich_drop_text_file',
    'rich_scale_images',
    'dod_start',
    'dod_type',
    'dod_gid',
    'dod_form_id',
    'dod_end',
    'dod_data',
    'dod_gain_focus',
    'dod_close_form',
    'dod_not_available',
    'dod_hints',
    'dod_lose_focus',
    'dod_no_hints',
    'dod_end_form',
    'dod_end_data',
    'dod_hints_no_action',
    'dod_tag',
    'dod_hints_mismatch',
    'radio_start_data',
    'radio_stop_data',
    'radio_change_station_id',
    'radio_data_type',
    'radio_append_data',
    'radio_play_data',
    'radio_record_id',
    'pictalk_manage',
    'pictalk_open_file',
    'pictalk_control',
    'pictalk_get_attr_style',
    'pictalk_attr_style',
    'pictalk_timeline_action',
    'pictalk_delete_timeline',
    'pictalk_attr_intl',
    'pictalk_attr_ctrl_id',
    'pictalk_attr_art_id',
    'pictalk_update_art',
    'pictalk_reset_styles',
    'pictalk_ad_manage',
    'doc_manage',
    'doc_edit_action',
    'doc_add_url',
    'doc_set_color_item',
    'doc_bool_read_only',
    'doc_open_file',
    'doc_remove_url',
    'doc_get_current_url',
    'doc_get_current_link',
    'doc_set_font_name',
    'doc_set_font_size',
    'doc_set_font_style',
    'doc_clear_font_style',
    'doc_set_text_alignment',
    'doc_get_font_name',
    'doc_get_font_size',
    'doc_get_font_style',
    'doc_get_text_alignment',
    'hfs_attr_field_mapping',
    'hfs_cmd_form_gid',
    'hfs_cmd_form_name',
    'hfs_attr_preset_global_id',
    'hfs_attr_style_id',
    'hfs_attr_cluster_id',
    'hfs_attr_flags',
    'hfs_cmd_fdo',
    'hfs_cmd_tag',
    'hfs_cmd_database_id',
    'hfs_cmd_result_code',
    'hfs_cmd_save_as',
    'hfs_attr_start_pre_stream',
    'hfs_attr_end_pre_stream',
    'hfs_attr_start_in_stream',
    'hfs_attr_end_in_stream',
    'hfs_attr_start_post_stream',
    'hfs_attr_end_post_stream',
    'hfs_cmd_start_form_data',
    'hfs_cmd_end_form_data',
    'hfs_cmd_start_save_result',
    'hfs_cmd_end_save_result',
    'hfs_cmd_start_get_fdo',
    'hfs_cmd_end_get_fdo',
    'hfs_cmd_start_install_result',
    'hfs_cmd_end_install_result',
    'hfs_cmd_result_message',
    'hfs_cmd_response_id',
    'hfs_attr_variable_mapping',
    'hfs_attr_dispatch_mapping',
    'hfs_attr_checkbox_mapping',
    'hfs_attr_database_type',
    'hfs_cmd_reference_id',
    'hfs_cmd_template_name',
    'hfs_attr_object_comment',
    'hfs_attr_object_name',
    'hfs_attr_fdo_comment',
    'hfs_attr_plus_group_number',
    'hfs_attr_plus_group_type',
    'hfs_attr_layer_name',
    'hfs_cmd_read_only_form',
    'hfs_attr_server_name',
    'hfs_cmd_access_rights',
    'hfs_attr_end_object',
    'hfs_attr_object_flags',
    'hfs_cmd_command',
    'hfs_cmd_position',
    'hfs_cmd_platform',
    'hfs_cmd_view_rule',
    'hfs_cmd_language',
    'hfs_cmd_start_result',
    'hfs_cmd_end_result',
    'hfs_cmd_process_mode',
    'blank_test',
    'blank_get_form',
    'blank_get_ver',
    'vid_capture',
    'vid_send',
    'vid_manage',
    'vid_preview',
    'vid_repeat_send',
    'vid_set_token',
    'vid_set_udp',
    'vid_set_tcp',
    'vid_receive',
    'vid_activate_capture',
    'vid_can_preview',
    'vid_set_format',
    'vid_receive_enable',
    'vid_is_available',
    'vid_prev_count',
    'vid_next_count',
    'vid_show_prev',
    'vid_show_next',
    'vid_show_oldest',
    'vid_show_newest',
    'vid_flag_setup',
    'vid_setup',
    'vid_open',
    'vid_save_as',
    'vid_setup_popup',
    'vid_get_data',
    'vid_enable_extract',
    'vid_query_setup',
    'vid_store_setup',
    'sec_ip_handshake_to_host',
    'sec_ip_handshake_to_client',
    'sec_ip_error',
    'gallery_create',
    'gallery_close',
    'gallery_view',
    'gallery_view_selected',
    'gallery_next_page',
    'gallery_prev_page',
    'gallery_open',
    'gallery_send_img_to_mail',
    'gallery_change_dir',
    'gallery_get_view_filename',
    'dice_set_context_tool',
    'dice_set_context_locality',
    'dice_end_context',
    'dice_enum_comm_devices',
    'dice_cancel_enum_devices',
    'dice_get_cur_locality_idx',
    'dice_set_cur_locality_idx',
    'dice_start_enumeration',
    'dice_restrict_type_enum',
    'dice_get_next_enum',
    'dice_connect',
    'dice_disconnect',
    'dice_get_obj_info',
    'dice_set_obj_attrib',
    'dice_set_obj_info',
    'dice_set_context_device',
    'dice_update_locality',
    'dice_update_device',
    'dice_device_refresh',
    'dice_send_message',
    'dice_get_tool_hardware_type',
    'dice_device_add_refresh',
    'dice_access_server_ready',
    'dice_access_search_view',
    'dice_access_current_update',
    'dice_access_update_country',
    'dice_access_update_area',
    'dice_access_update_number',
    'dice_access_set_bookmark',
    'dice_access_search',
    'dice_phone_session_stamp',
    'dice_phone_request',
    'dice_phone_clear_queue',
    'dice_access_req_invalid',
    'dice_access_req_complete',
    'dice_update_grouping',
    'dice_access_action',
    'dice_access_server_down',
    'dice_access_delete',
    'dice_access_search_form',
    'dice_access_search_error',
    'dice_disp_connect_summary',
    'dice_phone_clear_type',
    'spell_rules_dlg',
    'mf_add_filter',
    'mf_add_condition',
    'mf_modify_object',
    'mf_save_object',
    'adp_start',
    'adp_end',
    'adp_eid',
    'adp_route_tkn',
    'adp_abort_tkn',
    'adp_chunk_size',
    'adp_chunk_count',
    'adp_data',
    'adp_no_more_chunks',
    'adp_abort',
    'adp_eid_string',
    'adp_echo',
    'adp_more_chunks',
    'adp_transaction_type',
    'adp_meter_upload_cancel',
    'map_pp3_open_url',
    'map_pp3_open_site_mgr',
    'map_pp3_art_url',
    'map_pp3_get_url',
    'map_pp3_doaction',
    'map_pp3_menustart',
    'map_pp3_mywebpage',
    'map_pp3_entry_form',
    'map_pp3_selection_form',
    'map_pbm_canyoncreate',
    'map_pbm_fastcreate',
    'map_pbm_edit',
    'map_pbm_menulaunch',
    'map_pbm_doaction',
]);

const IsLineValid = (line: string) => {
    if (line.startsWith(';')) return true;

    const token = line.split(/[ ;<]/)[0];
    return token === '' || FDOTokens.has(token);
};

export { FDOTokens, IsLineValid };
