import { bgColor, caption, foreColor, BasePropDescriptors } from '@controls/CommonProps';
import { FontPropDescriptors } from '@controls/FontProps';
import { ControlPropsDescriptor, EnumPropValue } from '@controls/PropDescriptors';
import { AppearancePropGroup, BehaviorPropGroup } from '@controls/PropGroups';
import Props from './Props';

const TriggerStyles: EnumPropValue[] = [
    { name: 'Default', value: 'default' },
    { name: 'Rectangle', value: 'rectangle' },
    { name: 'Picture', value: 'picture' },
    { name: 'Plain Picture', value: 'plain_picture' },
    { name: 'Place', value: 'place' },
    { name: 'Framed', value: 'framed' },
];

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    ...BasePropDescriptors,
    caption: caption(),
    bgColor: bgColor(),
    foreColor: foreColor(),
    image: {
        inputType: 'art',
        propGroup: AppearancePropGroup,
        displayName: 'Image Asset',
        helpText: 'If set, the image for the button.',
    },
    isDefault: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Is Default',
        helpText: 'When enabled, specifies that the trigger should be executed when the user presses ENTER.',
    },
    ...FontPropDescriptors,
    triggerStyle: {
        inputType: 'enum',
        options: TriggerStyles,
        propGroup: AppearancePropGroup,
        displayName: 'Style',
        helpText: 'Controls the visual style of the trigger',
    },
};

export default PropsDescriptor;
