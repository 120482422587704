import { useCallback } from 'react';
import { useGlobal } from 'reactn';

function useHoverHelpText(message: string) {
    const [, setHelpText] = useGlobal('helpText');

    const onMouseEnter = useCallback(() => setHelpText(message), [message, setHelpText]);
    const onMouseLeave = useCallback(() => setHelpText(''), [setHelpText]);

    return { onMouseEnter, onMouseLeave };
}

export default useHoverHelpText;
