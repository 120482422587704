import tw, { styled } from 'twin.macro';
import ControlProperties from '@components/ControlProperties';
import useForm from '@hooks/useForm';

interface ContainerProps {
    hasControl?: boolean | undefined;
}

const Container = styled.div(({ hasControl }: ContainerProps) => [
    tw`w-80 shadow max-h-full border-l border-gray-300`,
    hasControl ? tw`bg-white` : tw`bg-gray-100`,
]);

const NoControlMessage = tw.span`p-8 text-xs text-gray-400`;

function NoFormPropertiesPane() {
    return (
        <Container>
            <NoControlMessage>Nothing selected.</NoControlMessage>
        </Container>
    );
}

function ControlPropertiesPane(selectedControlId: number) {
    return (
        <Container>
            <ControlProperties controlId={selectedControlId} />
        </Container>
    );
}

function PropertiesPane() {
    const { selectedControlId } = useForm();

    if (!selectedControlId) {
        return NoFormPropertiesPane();
    }

    return ControlPropertiesPane(selectedControlId);
}

export default PropertiesPane;
