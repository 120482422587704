import { useGlobal } from 'reactn';
import tw from 'twin.macro';
import GIDHelper from './GIDHelper';
import SaveIndicator from './SaveIndicator';

const Container = tw.div`flex flex-row bg-gray-700 p-1 text-xs space-x-4`;
const HelpText = tw.div`flex-grow text-white`;

function StatusBar() {
    const [helpText] = useGlobal('helpText');

    return (
        <Container>
            <HelpText>{helpText}</HelpText>
            <GIDHelper />
            <SaveIndicator />
        </Container>
    );
}

export default StatusBar;
