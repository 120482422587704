import { BasePropDescriptors, bgColor, caption, foreColor } from '@controls/CommonProps';
import { FontPropDescriptors } from '@controls/FontProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    ...BasePropDescriptors,
    caption: caption(),
    bgColor: bgColor(),
    foreColor: foreColor(),
    ...FontPropDescriptors,
};

export default PropsDescriptor;
