import { ArtApi, AuthenticationApi, Configuration, ProjectsApi } from '@services/api';
import { useMemo } from 'react';
import { useGlobal } from 'reactn';
import { getApiOrigin } from 'src/config';

export default function useApiClients() {
    const [auth, setAuth] = useGlobal('auth');
    const { accessToken } = auth;

    const config = useMemo(
        () =>
            new Configuration({
                accessToken,
                basePath: getApiOrigin(),
                middleware: [
                    {
                        post: async context => {
                            if (context.response?.status === 401) {
                                await setAuth({
                                    accessToken: '',
                                    refreshAt: new Date(),
                                    refreshToken: '',
                                    expiresAt: new Date(),
                                });
                            }
                        },
                        onError: async context => {
                            if (context.response?.status === 401) {
                                await setAuth({
                                    accessToken: '',
                                    refreshAt: new Date(),
                                    refreshToken: '',
                                    expiresAt: new Date(),
                                });
                            }
                        },
                    },
                ],
            }),
        [accessToken, setAuth],
    );
    const authClient = useMemo(() => new AuthenticationApi(config), [config]);
    const artClient = useMemo(() => new ArtApi(config), [config]);
    const projectsClient = useMemo(() => new ProjectsApi(config), [config]);

    return { isSignedIn: !!accessToken, authClient, artClient, projectsClient };
}
