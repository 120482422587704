/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArtListItemDto } from './ArtListItemDto';
import {
    ArtListItemDtoFromJSON,
    ArtListItemDtoFromJSONTyped,
    ArtListItemDtoToJSON,
} from './ArtListItemDto';

/**
 * 
 * @export
 * @interface ArtListDto
 */
export interface ArtListDto {
    /**
     * 
     * @type {Array<ArtListItemDto>}
     * @memberof ArtListDto
     */
    assets: Array<ArtListItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ArtListDto
     */
    hasMore: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArtListDto
     */
    token: number;
}

/**
 * Check if a given object implements the ArtListDto interface.
 */
export function instanceOfArtListDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "hasMore" in value;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function ArtListDtoFromJSON(json: any): ArtListDto {
    return ArtListDtoFromJSONTyped(json, false);
}

export function ArtListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': ((json['assets'] as Array<any>).map(ArtListItemDtoFromJSON)),
        'hasMore': json['hasMore'],
        'token': json['token'],
    };
}

export function ArtListDtoToJSON(value?: ArtListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': ((value.assets as Array<any>).map(ArtListItemDtoToJSON)),
        'hasMore': value.hasMore,
        'token': value.token,
    };
}

