/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExchangeRequestDto,
  ExchangeResponseDto,
  RefreshRequestDto,
  StartRequestDto,
  StartResponseDto,
} from '../models';
import {
    ExchangeRequestDtoFromJSON,
    ExchangeRequestDtoToJSON,
    ExchangeResponseDtoFromJSON,
    ExchangeResponseDtoToJSON,
    RefreshRequestDtoFromJSON,
    RefreshRequestDtoToJSON,
    StartRequestDtoFromJSON,
    StartRequestDtoToJSON,
    StartResponseDtoFromJSON,
    StartResponseDtoToJSON,
} from '../models';

export interface ExchangeRequest {
    exchangeRequestDto: ExchangeRequestDto;
}

export interface RefreshRequest {
    refreshRequestDto: RefreshRequestDto;
}

export interface StartRequest {
    startRequestDto: StartRequestDto;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     */
    async exchangeRaw(requestParameters: ExchangeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangeResponseDto>> {
        if (requestParameters.exchangeRequestDto === null || requestParameters.exchangeRequestDto === undefined) {
            throw new runtime.RequiredError('exchangeRequestDto','Required parameter requestParameters.exchangeRequestDto was null or undefined when calling exchange.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/exchange`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExchangeRequestDtoToJSON(requestParameters.exchangeRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async exchange(requestParameters: ExchangeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangeResponseDto> {
        const response = await this.exchangeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async infoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async info(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.infoRaw(initOverrides);
    }

    /**
     */
    async refreshRaw(requestParameters: RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangeResponseDto>> {
        if (requestParameters.refreshRequestDto === null || requestParameters.refreshRequestDto === undefined) {
            throw new runtime.RequiredError('refreshRequestDto','Required parameter requestParameters.refreshRequestDto was null or undefined when calling refresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshRequestDtoToJSON(requestParameters.refreshRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async refresh(requestParameters: RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangeResponseDto> {
        const response = await this.refreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async startRaw(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StartResponseDto>> {
        if (requestParameters.startRequestDto === null || requestParameters.startRequestDto === undefined) {
            throw new runtime.RequiredError('startRequestDto','Required parameter requestParameters.startRequestDto was null or undefined when calling start.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartRequestDtoToJSON(requestParameters.startRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async start(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StartResponseDto> {
        const response = await this.startRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
