import { useCallback, useState } from 'react';
import { getApiOrigin } from 'src/config';

function useLogin() {
    const [isBusy, setIsBusy] = useState(false);

    const getLoginUrl = useCallback(async (redirectUrl: string) => {
        setIsBusy(true);

        try {
            const result = await fetch(`${getApiOrigin()}/auth/start`, {
                method: 'POST',
                body: JSON.stringify({ redirectUrl }),
                headers: { 'Content-Type': 'application/json' },
            });
            const json = await result.json();
            return json.authorizeUrl as string;
        } catch {
            return null;
        } finally {
            setIsBusy(false);
        }
    }, []);

    const exchangeForToken = useCallback(async (authCode: string, state: string) => {
        setIsBusy(true);

        try {
            const result = await fetch(`${getApiOrigin()}/auth/exchange`, {
                method: 'POST',
                body: JSON.stringify({ authCode, state }),
                headers: { 'Content-Type': 'application/json' },
            });
            const json = await result.json();
            return {
                accessToken: json.accessToken as string,
                redirectUrl: json.redirectUrl as string,
                refreshToken: json.refreshToken as string,
                expiresIn: json.expiresIn as number,
            };
        } catch {
            return null;
        } finally {
            setIsBusy(false);
        }
    }, []);

    const performTokenRefresh = useCallback(async (accessToken: string, refreshToken: string) => {
        setIsBusy(true);

        try {
            const result = await fetch(`${getApiOrigin()}/auth/refresh`, {
                method: 'POST',
                body: JSON.stringify({ refreshToken }),
                headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
            });
            const json = await result.json();
            return {
                accessToken: json.accessToken as string,
                redirectUrl: json.redirectUrl as string,
                refreshToken: json.refreshToken as string,
                expiresIn: json.expiresIn as number,
            };
        } catch {
            return null;
        } finally {
            setIsBusy(false);
        }
    }, []);

    return { getLoginUrl, exchangeForToken, performTokenRefresh, isBusy };
}

export default useLogin;
