/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LabelPropsDto } from './LabelPropsDto';
import {
    LabelPropsDtoFromJSON,
    LabelPropsDtoFromJSONTyped,
    LabelPropsDtoToJSON,
} from './LabelPropsDto';

/**
 * 
 * @export
 * @interface LabelControlDto
 */
export interface LabelControlDto {
    /**
     * 
     * @type {LabelPropsDto}
     * @memberof LabelControlDto
     */
    properties: LabelPropsDto;
    /**
     * 
     * @type {string}
     * @memberof LabelControlDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof LabelControlDto
     */
    relativeId: number;
    /**
     * 
     * @type {number}
     * @memberof LabelControlDto
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof LabelControlDto
     */
    name: string;
}

/**
 * Check if a given object implements the LabelControlDto interface.
 */
export function instanceOfLabelControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "relativeId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function LabelControlDtoFromJSON(json: any): LabelControlDto {
    return LabelControlDtoFromJSONTyped(json, false);
}

export function LabelControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': LabelPropsDtoFromJSON(json['properties']),
        'type': json['type'],
        'relativeId': json['relativeId'],
        'parentId': json['parentId'],
        'name': json['name'],
    };
}

export function LabelControlDtoToJSON(value?: LabelControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': LabelPropsDtoToJSON(value.properties),
        'type': value.type,
        'relativeId': value.relativeId,
        'parentId': value.parentId,
        'name': value.name,
    };
}

