import '@assets/fonts.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Login from '@pages/Login';
import OAuthReply from '@pages/OAuthReply';
import Index from '@pages/Index';
import Project from '@pages/Project';
import RequireAuth from './RequireAuth';

function Navigator() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route index element={<Index />} />
                    <Route path="/projects/:projectId" element={<Project />} />
                </Route>

                <Route path="/oauth-reply" element={<OAuthReply />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Navigator;
