import { useCallback, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import Header from '@components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useNavigate } from 'react-router-dom';
import useProjectList from '@hooks/useProjectList';
import { ProjectType } from '@services/api';
import { Input, PrimaryButton, SecondaryButton } from '@components/common';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full`;
const Viewport = styled.div(() => [
    tw`flex flex-grow flex-row space-x-2 items-center justify-center`,
    'max-height: calc(100% - 100px);',
]);
const ModalContainer = tw.div`bg-white shadow rounded border border-gray-200 p-4 flex flex-row space-x-4`;
const Section = tw.div`flex flex-col space-y-4 bg-gray-100 w-96 p-4 shadow-lg h-96`;
const SectionTitle = tw.span`text-lg font-bold`;
interface ButtonProps {
    isSelected: boolean;
}

const ButtonStyles = tw`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 flex flex-col items-start text-left space-y-4 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const SelectedButtonStyles = tw`border-blue-400 text-black cursor-default`;
const Button = styled.button(({ isSelected }: ButtonProps) => [ButtonStyles, isSelected ? SelectedButtonStyles : '']);
const ButtonLink = styled(Link)(() => [ButtonStyles]);
const CreateButtons = tw.div`flex flex-row justify-end space-x-2`;
const Title = tw.h2`text-base font-bold`;
const Description = tw.span`leading-relaxed`;
const SpinnerContainer = tw.div`h-8 flex items-center justify-center`;
const Spinner = styled(FontAwesomeIcon)(() => [tw`animate-spin`]);
const ProjectList = tw.div`flex flex-col space-y-2 overflow-y-scroll h-72 px-2`;
const NoProjects = tw.span`leading-relaxed text-gray-500`;

function Index() {
    const navigate = useNavigate();
    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectType, setNewProjectType] = useState<ProjectType>();
    const { isReady, projects, createProject } = useProjectList();
    const isValid = newProjectName && newProjectType;

    useEffect(() => {
        setNewProjectName('');
    }, [newProjectType]);

    const handleCreate = useCallback(async () => {
        if (!newProjectName || !newProjectType) return;

        const result = await createProject(newProjectName, newProjectType);

        if (result) {
            navigate(`/projects/${result.id}`);
        }
    }, [newProjectName, newProjectType, createProject, navigate]);

    return (
        <Container>
            <Header />
            <Viewport>
                <ModalContainer>
                    <Section>
                        <SectionTitle>Create New Project</SectionTitle>
                        {newProjectType !== ProjectType.Modern && (
                            <Button
                                isSelected={newProjectType === ProjectType.Standard}
                                type="button"
                                onClick={() => setNewProjectType(ProjectType.Standard)}
                            >
                                <Title>New Standard Project</Title>
                                <Description>
                                    Creates a new project using legacy FDO to provide interactivity. Great for the true
                                    retro experience, just don&apos;t expect documentation!
                                </Description>
                            </Button>
                        )}
                        {newProjectType !== ProjectType.Standard && (
                            <Button
                                isSelected={newProjectType === ProjectType.Modern}
                                type="button"
                                onClick={() => setNewProjectType(ProjectType.Modern)}
                            >
                                <Title>New Modern Project</Title>
                                <Description>
                                    Creates a new project leveraging Modern Framework to provide interactivity. Control
                                    and update your forms using modern backend languages like NodeJS, Python, or .NET
                                    via webhooks and API calls. Who says FDO died in the last millenium?
                                </Description>
                            </Button>
                        )}
                        {newProjectType && (
                            <>
                                <Input
                                    placeholder="Name of the project"
                                    value={newProjectName}
                                    onChange={e => setNewProjectName(e.target.value)}
                                />
                                <CreateButtons>
                                    <SecondaryButton onClick={() => setNewProjectType(undefined)}>
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton onClick={handleCreate} disabled={!isValid}>
                                        Create Project
                                    </PrimaryButton>
                                </CreateButtons>
                            </>
                        )}
                    </Section>
                    <Section>
                        <SectionTitle>Existing Projects</SectionTitle>
                        {!isReady && (
                            <SpinnerContainer>
                                <Spinner icon={solid('spinner')} />
                            </SpinnerContainer>
                        )}
                        {isReady && (
                            <>
                                {projects?.length === 0 && (
                                    <NoProjects>You have no saved projects. Why not create one?</NoProjects>
                                )}
                                {projects && projects.length > 0 && (
                                    <ProjectList>
                                        {projects.map(project => (
                                            <ButtonLink key={project.id} to={`/projects/${project.id}`}>
                                                {project.name} ({project.type})
                                            </ButtonLink>
                                        ))}
                                    </ProjectList>
                                )}
                            </>
                        )}
                    </Section>
                </ModalContainer>
            </Viewport>
        </Container>
    );
}

export default Index;
