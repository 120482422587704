/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelativePositionPropsDto
 */
export interface RelativePositionPropsDto {
    /**
     * 
     * @type {string}
     * @memberof RelativePositionPropsDto
     */
    placement: string;
    /**
     * 
     * @type {string}
     * @memberof RelativePositionPropsDto
     */
    orientation: string;
    /**
     * 
     * @type {string}
     * @memberof RelativePositionPropsDto
     */
    hAlign: string;
    /**
     * 
     * @type {string}
     * @memberof RelativePositionPropsDto
     */
    vAlign: string;
}

/**
 * Check if a given object implements the RelativePositionPropsDto interface.
 */
export function instanceOfRelativePositionPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placement" in value;
    isInstance = isInstance && "orientation" in value;
    isInstance = isInstance && "hAlign" in value;
    isInstance = isInstance && "vAlign" in value;

    return isInstance;
}

export function RelativePositionPropsDtoFromJSON(json: any): RelativePositionPropsDto {
    return RelativePositionPropsDtoFromJSONTyped(json, false);
}

export function RelativePositionPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelativePositionPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placement': json['placement'],
        'orientation': json['orientation'],
        'hAlign': json['hAlign'],
        'vAlign': json['vAlign'],
    };
}

export function RelativePositionPropsDtoToJSON(value?: RelativePositionPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placement': value.placement,
        'orientation': value.orientation,
        'hAlign': value.hAlign,
        'vAlign': value.vAlign,
    };
}

