/* eslint-disable import/prefer-default-export */
const getApiOrigin = () => {
    if (window.location.host === 'revpd.p3ol.ca') {
        return 'https://revpd-api.p3ol.ca';
    }
    if (window.location.host === 'revpd-staging.p3ol.ca') {
        return 'https://revpd-api-staging.p3ol.ca';
    }
    if (window.location.host === 'host.docker.internal:8014') {
        return 'http://host.docker.internal:8013';
    }

    return 'http://localhost:3003';
};

export { getApiOrigin };
