import { Grammar } from 'prismjs';

const FDOLanguage: Grammar = {
    comment: /;.*/,
    punctuation: /<|>/,
    string: /"(?:""|[^"\r\f\n])*"/,
    number: /\d/,
    function: /[a-z_]/,
};

export default FDOLanguage;
