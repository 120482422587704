import useForm from '@hooks/useForm';
import tw, { styled } from 'twin.macro';

interface ContainerProps {
    saveState: 'saved' | 'saving' | 'unsaved';
}

const Container = styled.div(({ saveState }: ContainerProps) => [
    tw`text-xs`,
    saveState === 'saved' ? tw`text-gray-300` : '',
    saveState === 'saving' ? tw`text-blue-500 font-bold` : '',
    saveState === 'unsaved' ? tw`text-white font-bold` : '',
]);

function SaveIndicator() {
    const { isDirty, isSaving } = useForm();
    const saveState = (() => {
        if (isSaving) return 'saving';
        if (isDirty) return 'unsaved';
        return 'saved';
    })();

    return (
        <Container saveState={saveState}>
            {saveState === 'saving' && <span>Saving changes...</span>}
            {saveState === 'unsaved' && <span>Unsaved changes</span>}
            {saveState === 'saved' && <span>Saved</span>}
        </Container>
    );
}

export default SaveIndicator;
