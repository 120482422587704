import tw, { styled } from 'twin.macro';
import Header from '@components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useLogin from '@hooks/useLogin';
import { useGlobal } from 'reactn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full`;
const Viewport = styled.div(() => [
    tw`flex flex-grow flex-row space-x-2 items-center justify-center`,
    'max-height: calc(100% - 100px);',
]);
const ModalContainer = tw.div`bg-white shadow rounded border border-gray-200 p-4 flex flex-row space-x-4 w-96 h-32 items-center justify-center`;
const SpinnerContainer = tw.div`h-8 flex items-center justify-center`;
const Spinner = styled(FontAwesomeIcon)(() => [tw`animate-spin`]);
const ErrorContainer = tw.div`bg-red-100 border border-red-200 p-2 flex items-center justify-center text-xs`;

function OAuthReply() {
    const isFetchingToken = useRef(false);
    const [, setAuth] = useGlobal('auth');
    const [hasError, setHasError] = useState(false);
    const { exchangeForToken } = useLogin();
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;

    useEffect(() => {
        if (isFetchingToken.current) return;
        isFetchingToken.current = true;

        if (search) {
            setHasError(false);
            const doExchange = async () => {
                try {
                    const returnParams = new URLSearchParams(search.substring(1));
                    const code = returnParams.get('code');
                    const state = returnParams.get('state');

                    if (!code || !state) throw new Error('Oopsies');
                    const token = await exchangeForToken(code, state);

                    if (token) {
                        const expiresAt = new Date();
                        expiresAt.setTime(expiresAt.getTime() + token.expiresIn * 1000);

                        const refreshAt = new Date();
                        refreshAt.setTime(refreshAt.getTime() + (token.expiresIn - 300) * 1000);

                        await setAuth({
                            accessToken: token.accessToken,
                            refreshToken: token.refreshToken,
                            expiresAt,
                            refreshAt,
                        });
                        navigate(token.redirectUrl);
                    } else {
                        // Handle error
                        setHasError(true);
                    }
                } catch {
                    setHasError(true);
                }
            };

            doExchange();
        } else {
            setHasError(true);
        }
    }, [search, exchangeForToken, setAuth, navigate]);

    return (
        <Container>
            <Header />
            <Viewport>
                <ModalContainer>
                    {!hasError && (
                        <SpinnerContainer>
                            <Spinner icon={solid('spinner')} />
                        </SpinnerContainer>
                    )}
                    {hasError && (
                        <ErrorContainer>There was a problem signing in. Please try again later.</ErrorContainer>
                    )}
                </ModalContainer>
            </Viewport>
        </Container>
    );
}

export default OAuthReply;
