import { ArtListDto, ArtSource } from '@services/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { ArtListItemDto } from 'src/models';
import useApiClients from './useApiClients';

function useArtList(source: ArtSource) {
    const { artClient } = useApiClients();
    const fetchLock = useRef(false);
    const [assets] = useGlobal('assets');
    const lastMode = useRef<ArtSource>();
    const [hasMore, setHasMore] = useState(false);
    const appendAssets = useDispatch(
        (state, val: Record<string, ArtListItemDto>) => ({
            ...state,
            [source]: {
                ...state[source],
                items: {
                    ...state[source].items,
                    ...val,
                },
            },
        }),
        'assets',
    );
    const setIsLoading = useDispatch(
        (state, val: boolean) => ({ ...state, [source]: { ...state[source], loading: val } }),
        'assets',
    );
    const setToken = useDispatch(
        (state, val: number) => ({ ...state, [source]: { ...state[source], lastToken: val } }),
        'assets',
    );

    const { loading, lastToken } = assets[source];

    const loadMore = useCallback(async () => {
        if (loading || fetchLock.current) return;
        fetchLock.current = true;

        await setIsLoading(true);

        try {
            let data: ArtListDto;

            if (source === 'owned') {
                data = await artClient.getOwnedArt({
                    token: lastToken,
                });
            } else {
                data = await artClient.getSystemArt({
                    token: lastToken,
                });
            }

            setToken(data.token);
            appendAssets(Object.fromEntries(data.assets.map(a => [a.gid, a])));
            setHasMore(data.hasMore);
            setIsLoading(false);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            setIsLoading(false);
        } finally {
            fetchLock.current = false;
        }
    }, [source, setIsLoading, setToken, loading, lastToken, appendAssets, artClient]);

    useEffect(() => {
        if (lastMode.current !== source) {
            lastMode.current = source;
            loadMore();
        }
    }, [loadMore, source]);

    return { isLoading: loading, hasMore, assets: assets[source].items, loadMore };
}

export default useArtList;
