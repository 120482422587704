import { useCallback } from 'react';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import useForm from '@hooks/useForm';

const Input = tw.input`rounded border border-gray-200 mt-1 w-4 h-4 cursor-pointer hover:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    id: string;
    name: string;
}

function BoolProperty({ id, name }: Props) {
    const { selectedControlId } = useForm();
    const { control, updateProperties } = useControl(selectedControlId!);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = control.properties[name];
    const handleChange = useCallback(
        (newValue: boolean) => {
            const newProps = {
                [name]: newValue,
            };
            updateProperties(newProps);
        },
        [name, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <Input
            id={id}
            type="checkbox"
            checked={!!value}
            value="1"
            onChange={v => handleChange(v.currentTarget.checked)}
        />
    );
}

export default BoolProperty;
