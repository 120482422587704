import useForm from '@hooks/useForm';
import tw, { styled } from 'twin.macro';
import ControlTreeNode from './ControlTreeNode';
import PreviewButton from './PreviewButton';

const Pane = tw.div`h-1/2 w-full`;
const Header = tw.div`w-full flex flex-row bg-gray-600 text-gray-200 p-2 justify-between leading-none`;
const PaneHeader = tw.p`text-xs font-bold`;
const Actions = tw.div`flex flex-row space-x-2`;
const Tree = styled.div(() => [tw`px-2 overflow-y-auto`, 'max-height: calc(100% - 32px);']);

function ControlTreePane() {
    const { getWindow } = useForm();
    const window = getWindow();

    if (!window) return null;

    return (
        <Pane>
            <Header>
                <PaneHeader>Form Explorer</PaneHeader>
                <Actions>
                    <PreviewButton />
                </Actions>
            </Header>
            <Tree>
                <ControlTreeNode indent={0} controlId={window.relativeId} />
            </Tree>
        </Pane>
    );
}

export default ControlTreePane;
