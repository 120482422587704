import { useCallback, ReactElement, MouseEvent } from 'react';

import tw from 'twin.macro';

const Container = tw.button`flex flex-row space-x-2 text-gray-600 hover:(bg-blue-100 bg-opacity-50 text-blue-600)`;
const Icon = tw.div`w-4 h-4`;
const Text = tw.span`capitalize`;

interface Props {
    icon?: ReactElement | undefined;
    text: string;
    onSelect: () => void;
}

function PopupMenuItem({ icon, text, onSelect }: Props) {
    const handleSelect = useCallback(
        (evt: MouseEvent) => {
            onSelect();
            evt.stopPropagation();
        },
        [onSelect],
    );
    return (
        <Container onClick={handleSelect}>
            <Icon>{icon}</Icon>
            <Text>{text}</Text>
        </Container>
    );
}

PopupMenuItem.defaultProps = {
    icon: undefined,
};

export default PopupMenuItem;
