/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface PicturePropsDto
 */
export interface PicturePropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PicturePropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof PicturePropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {number}
     * @memberof PicturePropsDto
     */
    renderOrder: number;
    /**
     * 
     * @type {string}
     * @memberof PicturePropsDto
     */
    asset: string;
    /**
     * 
     * @type {boolean}
     * @memberof PicturePropsDto
     */
    isAnimated: boolean;
    /**
     * 
     * @type {number}
     * @memberof PicturePropsDto
     */
    animationRate: number;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof PicturePropsDto
     */
    position: ButtonPropsDtoPosition;
}

/**
 * Check if a given object implements the PicturePropsDto interface.
 */
export function instanceOfPicturePropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "renderOrder" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "isAnimated" in value;
    isInstance = isInstance && "animationRate" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function PicturePropsDtoFromJSON(json: any): PicturePropsDto {
    return PicturePropsDtoFromJSONTyped(json, false);
}

export function PicturePropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PicturePropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'renderOrder': json['renderOrder'],
        'asset': json['asset'],
        'isAnimated': json['isAnimated'],
        'animationRate': json['animationRate'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
    };
}

export function PicturePropsDtoToJSON(value?: PicturePropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'renderOrder': value.renderOrder,
        'asset': value.asset,
        'isAnimated': value.isAnimated,
        'animationRate': value.animationRate,
        'position': ButtonPropsDtoPositionToJSON(value.position),
    };
}

