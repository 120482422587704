import { ActionControl } from '@controls/Base';
import ControlPackages from '@controls/index';
import { useCallback, useMemo } from 'react';
import useForm from './useForm';

export default function useControl(controlId: number) {
    const { getControlById, updateControl } = useForm();
    const control = getControlById(controlId);

    if (!control) {
        throw new Error('Control not found');
    }

    const { properties } = control;

    const rename = useCallback(
        (newName: string) => {
            updateControl(controlId, { name: newName });
        },
        [controlId, updateControl],
    );

    const updateProperties = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (updatedProps: any) => {
            const existingProps = { ...properties };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const applyUpdate = (partial: any, target: any) => {
                const result = { ...target };

                Object.keys(target).forEach(k => {
                    const partialType = typeof partial[k];

                    if (partialType === 'object') {
                        if (Object.keys(target).includes(k)) {
                            result[k] = applyUpdate(partial[k], target[k]);
                        } else {
                            result[k] = partial[k];
                        }
                    } else if (partialType !== 'undefined') {
                        result[k] = partial[k];
                    }
                });

                return result;
            };

            const existingWithUpdates = applyUpdate(updatedProps, existingProps);
            updateControl(controlId, { properties: existingWithUpdates });
        },
        [controlId, properties, updateControl],
    );

    const getActionFDO = useCallback(() => {
        const controlPackage = ControlPackages[control.type];
        if (!controlPackage.canHaveAction) throw new Error(`${control.type} does not support actions`);

        return (control as unknown as ActionControl).actionFDO;
    }, [control]);

    const setActionFDO = useCallback(
        (value: string) => {
            const controlPackage = ControlPackages[control.type];
            if (!controlPackage.canHaveAction) throw new Error(`${control.type} does not support actions`);

            updateControl(controlId, { actionFDO: value });
        },
        [control, controlId, updateControl],
    );

    const returnValue = useMemo(
        () => ({ control, getActionFDO, setActionFDO, updateProperties, rename }),
        [control, getActionFDO, setActionFDO, updateProperties, rename],
    );
    return returnValue;
}
