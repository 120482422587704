import { PaneHeaderButton } from '@components/common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHoverHelpText from '@hooks/useHoverHelpText';
import useProject from '@hooks/useProject';
import { useCallback, useState } from 'react';

function PublishButton() {
    const { onMouseEnter, onMouseLeave } = useHoverHelpText(
        'Publishes all forms in the project to the global database.',
    );
    const [publishState, setPublishState] = useState<'idle' | 'publishing' | 'success' | 'error'>('idle');
    const { publish } = useProject();

    const handlePublish = useCallback(async () => {
        if (publishState !== 'idle' || !publish) return;
        setPublishState('publishing');

        try {
            await publish();
            setPublishState('success');
        } catch {
            setPublishState('error');
        }

        setTimeout(() => setPublishState('idle'), 1000);
    }, [publishState, publish]);

    const getPublishCaption = () => {
        switch (publishState) {
            case 'publishing':
                return 'Publishing';
            case 'success':
                return 'Published';
            case 'error':
                return 'Publish Failed';
            case 'idle':
            default:
                return 'Publish';
        }
    };

    return (
        <PaneHeaderButton type="button" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handlePublish}>
            {publishState === 'idle' && <FontAwesomeIcon size="lg" icon={solid('upload')} />}
            {publishState === 'publishing' && <FontAwesomeIcon size="lg" spin icon={solid('spinner')} />}
            {publishState === 'success' && <FontAwesomeIcon size="lg" icon={solid('check')} />}
            {publishState === 'error' && <FontAwesomeIcon size="lg" icon={solid('triangle-exclamation')} />}
            <span>{getPublishCaption()}</span>
        </PaneHeaderButton>
    );
}

export default PublishButton;
