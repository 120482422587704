/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface RadioListPropsDto
 */
export interface RadioListPropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof RadioListPropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof RadioListPropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {number}
     * @memberof RadioListPropsDto
     */
    renderOrder: number;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof RadioListPropsDto
     */
    position: ButtonPropsDtoPosition;
}

/**
 * Check if a given object implements the RadioListPropsDto interface.
 */
export function instanceOfRadioListPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "renderOrder" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function RadioListPropsDtoFromJSON(json: any): RadioListPropsDto {
    return RadioListPropsDtoFromJSONTyped(json, false);
}

export function RadioListPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RadioListPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'renderOrder': json['renderOrder'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
    };
}

export function RadioListPropsDtoToJSON(value?: RadioListPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'renderOrder': value.renderOrder,
        'position': ButtonPropsDtoPositionToJSON(value.position),
    };
}

