import { createElement } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ControlPackage, ensureSet } from '@controls/Base';
import Control from './Control';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';
import Window from './Window';

const createWindow = (): Control => ({
    relativeId: -1,
    name: 'NewWindow',
    type: 'window',
    properties: {
        caption: 'New Window',
        position: {
            placement: 'precise',
            width: 640,
            height: 480,
            top: 0,
            left: 0,
        },
        frameStyle: '0',
        bgColor: '#ffffff',
        bgArt: '',
        windowPosition: '0',
        modal: false,
        resizableH: true,
        resizableV: true,
        autoCloseOnWindowLimit: true,
        referenceImage: '',
        isEnabled: true,
        renderOrder: 1,
    },
});

const upgradePropsVersion = (props: Props) => ensureSet(props, 'referenceImage', '');

const windowPackage: ControlPackage<Props, Control> = {
    type: 'window',
    icon: solid('window-maximize'),
    canHaveChildren: true,
    canHaveAction: false,
    factory: createWindow,
    propDescriptors: PropsDescriptor,
    // eslint-disable-next-line react/no-children-prop
    renderDesigner: (controlId, children) => createElement(Window, { controlId, children }),
    upgradePropsVersion,
};

export default windowPackage;
