/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckboxPropsDto } from './CheckboxPropsDto';
import {
    CheckboxPropsDtoFromJSON,
    CheckboxPropsDtoFromJSONTyped,
    CheckboxPropsDtoToJSON,
} from './CheckboxPropsDto';

/**
 * 
 * @export
 * @interface CheckboxControlDto
 */
export interface CheckboxControlDto {
    /**
     * 
     * @type {CheckboxPropsDto}
     * @memberof CheckboxControlDto
     */
    properties: CheckboxPropsDto;
    /**
     * 
     * @type {string}
     * @memberof CheckboxControlDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CheckboxControlDto
     */
    relativeId: number;
    /**
     * 
     * @type {number}
     * @memberof CheckboxControlDto
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckboxControlDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CheckboxControlDto
     */
    actionFDO: string;
}

/**
 * Check if a given object implements the CheckboxControlDto interface.
 */
export function instanceOfCheckboxControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "relativeId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "actionFDO" in value;

    return isInstance;
}

export function CheckboxControlDtoFromJSON(json: any): CheckboxControlDto {
    return CheckboxControlDtoFromJSONTyped(json, false);
}

export function CheckboxControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckboxControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': CheckboxPropsDtoFromJSON(json['properties']),
        'type': json['type'],
        'relativeId': json['relativeId'],
        'parentId': json['parentId'],
        'name': json['name'],
        'actionFDO': json['actionFDO'],
    };
}

export function CheckboxControlDtoToJSON(value?: CheckboxControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': CheckboxPropsDtoToJSON(value.properties),
        'type': value.type,
        'relativeId': value.relativeId,
        'parentId': value.parentId,
        'name': value.name,
        'actionFDO': value.actionFDO,
    };
}

