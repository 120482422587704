import { useCallback } from 'react';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import { ColorPropDescriptor } from '@controls/PropDescriptors';
import useForm from '@hooks/useForm';

const InputContainer = tw.div`flex flex-row items-center space-x-4`;
const Input = tw.input`rounded border border-gray-200 mt-1 w-10 h-10 cursor-pointer hover:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const Label = tw.label`cursor-pointer flex flex-row space-x-1`;
const Transparent = tw.input`rounded border border-gray-200 mt-1 w-4 h-4 cursor-pointer hover:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    name: string;
    descriptor: ColorPropDescriptor;
}

function ColorProperty({ name, descriptor }: Props) {
    const { selectedControlId } = useForm();
    const { control, updateProperties } = useControl(selectedControlId!);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = control.properties[name];
    const handleChange = useCallback(
        (newValue: string) => {
            const newProps = {
                [name]: newValue,
            };
            updateProperties(newProps);
        },
        [name, updateProperties],
    );
    const handleTransparentChange = useCallback(
        (isTransparent: boolean) => {
            console.log(isTransparent);
            if (isTransparent) {
                const newProps = {
                    [name]: 'transparent',
                };
                updateProperties(newProps);
            } else {
                const newProps = {
                    [name]: '#ff0000',
                };
                updateProperties(newProps);
            }
        },
        [name, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <InputContainer>
            <Input type="color" value={value} onChange={v => handleChange(v.currentTarget.value)} />
            {descriptor.allowTransparent && (
                <Label>
                    <Transparent
                        type="checkbox"
                        value="1"
                        checked={value === 'transparent'}
                        onChange={evt => handleTransparentChange(evt.currentTarget.checked)}
                    />
                    <span>Is Transparent</span>
                </Label>
            )}
        </InputContainer>
    );
}

export default ColorProperty;
