/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArtSource } from './ArtSource';
import {
    ArtSourceFromJSON,
    ArtSourceFromJSONTyped,
    ArtSourceToJSON,
} from './ArtSource';

/**
 * 
 * @export
 * @interface ArtListItemDto
 */
export interface ArtListItemDto {
    /**
     * 
     * @type {string}
     * @memberof ArtListItemDto
     */
    gid: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArtListItemDto
     */
    isApproved: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArtListItemDto
     */
    preview: string;
    /**
     * 
     * @type {ArtSource}
     * @memberof ArtListItemDto
     */
    source: ArtSource;
}

/**
 * Check if a given object implements the ArtListItemDto interface.
 */
export function instanceOfArtListItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gid" in value;
    isInstance = isInstance && "isApproved" in value;
    isInstance = isInstance && "preview" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function ArtListItemDtoFromJSON(json: any): ArtListItemDto {
    return ArtListItemDtoFromJSONTyped(json, false);
}

export function ArtListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtListItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gid': json['gid'],
        'isApproved': json['isApproved'],
        'preview': json['preview'],
        'source': ArtSourceFromJSON(json['source']),
    };
}

export function ArtListItemDtoToJSON(value?: ArtListItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gid': value.gid,
        'isApproved': value.isApproved,
        'preview': value.preview,
        'source': ArtSourceToJSON(value.source),
    };
}

