/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface RadioPropsDto
 */
export interface RadioPropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof RadioPropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof RadioPropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {number}
     * @memberof RadioPropsDto
     */
    renderOrder: number;
    /**
     * 
     * @type {string}
     * @memberof RadioPropsDto
     */
    caption: string;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof RadioPropsDto
     */
    position: ButtonPropsDtoPosition;
    /**
     * 
     * @type {string}
     * @memberof RadioPropsDto
     */
    bgColor: string;
    /**
     * 
     * @type {string}
     * @memberof RadioPropsDto
     */
    foreColor: string;
    /**
     * 
     * @type {string}
     * @memberof RadioPropsDto
     */
    fontFace: string;
    /**
     * 
     * @type {number}
     * @memberof RadioPropsDto
     */
    fontSize: number;
    /**
     * 
     * @type {boolean}
     * @memberof RadioPropsDto
     */
    fontBold: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RadioPropsDto
     */
    fontItalic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RadioPropsDto
     */
    fontUnderline: boolean;
}

/**
 * Check if a given object implements the RadioPropsDto interface.
 */
export function instanceOfRadioPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "renderOrder" in value;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "bgColor" in value;
    isInstance = isInstance && "foreColor" in value;
    isInstance = isInstance && "fontFace" in value;
    isInstance = isInstance && "fontSize" in value;
    isInstance = isInstance && "fontBold" in value;
    isInstance = isInstance && "fontItalic" in value;
    isInstance = isInstance && "fontUnderline" in value;

    return isInstance;
}

export function RadioPropsDtoFromJSON(json: any): RadioPropsDto {
    return RadioPropsDtoFromJSONTyped(json, false);
}

export function RadioPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RadioPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'renderOrder': json['renderOrder'],
        'caption': json['caption'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
        'bgColor': json['bgColor'],
        'foreColor': json['foreColor'],
        'fontFace': json['fontFace'],
        'fontSize': json['fontSize'],
        'fontBold': json['fontBold'],
        'fontItalic': json['fontItalic'],
        'fontUnderline': json['fontUnderline'],
    };
}

export function RadioPropsDtoToJSON(value?: RadioPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'renderOrder': value.renderOrder,
        'caption': value.caption,
        'position': ButtonPropsDtoPositionToJSON(value.position),
        'bgColor': value.bgColor,
        'foreColor': value.foreColor,
        'fontFace': value.fontFace,
        'fontSize': value.fontSize,
        'fontBold': value.fontBold,
        'fontItalic': value.fontItalic,
        'fontUnderline': value.fontUnderline,
    };
}

