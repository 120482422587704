import {
    BoolPropDescriptor,
    ColorPropDescriptor,
    EnumPropDescriptor,
    PositionPropDescriptor,
    ReadOnlyPropDescriptor,
    TextPropDescriptor,
} from './PropDescriptors';
import { AppearancePropGroup, PositionPropGroup } from './PropGroups';

const position = (): PositionPropDescriptor => ({
    inputType: 'position',
    propGroup: PositionPropGroup,
    displayName: 'Object Positioning',
    helpText: 'Describes layout of the object.',
});

const isEnabled = (): BoolPropDescriptor => ({
    inputType: 'boolean',
    propGroup: AppearancePropGroup,
    displayName: 'Is Enabled',
    helpText: 'Specifies whether this control is enabled (interactive) or not.',
});

const frameStyle = (): EnumPropDescriptor => ({
    inputType: 'enum',
    propGroup: AppearancePropGroup,
    displayName: 'Frame Style',
    helpText: 'Specifies the type of frame style to use.',
    options: [
        { name: 'Single line border (Windows default)', value: '0' },
        { name: 'Single line border with pop-out shadowing', value: '1' },
        { name: 'Single line border with pop-in shadowing', value: '2' },
        { name: 'No border with pop-in shadowing', value: '3' },
        { name: 'No border with pop-out shadowing', value: '4' },
        { name: 'Double-line border', value: '5' },
        { name: 'Standard shadowing', value: '6' },
        { name: 'Highlighting for static text', value: '7' },
    ],
});

const renderOrder = (): ReadOnlyPropDescriptor => ({
    inputType: 'read-only',
    propGroup: AppearancePropGroup,
    displayName: 'Render Order',
    helpText: 'The order in which this element is rendered.',
});

const caption = (): TextPropDescriptor => ({
    inputType: 'text',
    propGroup: AppearancePropGroup,
    displayName: 'Caption',
    helpText: 'The title of the object.',
    minLength: 1,
    maxLength: 255,
    isMultiline: false,
});

const bgColor = (): ColorPropDescriptor => ({
    inputType: 'color',
    propGroup: AppearancePropGroup,
    displayName: 'BG Color',
    helpText: 'The background color for the object (RGB).',
    allowTransparent: true,
});

const foreColor = (): ColorPropDescriptor => ({
    inputType: 'color',
    propGroup: AppearancePropGroup,
    displayName: 'Foreground Color',
    helpText: 'The foreground (text, etc) color for the object (RGB).',
    allowTransparent: false,
});

const BasePropDescriptors = {
    position: position(),
    isEnabled: isEnabled(),
    frameStyle: frameStyle(),
    renderOrder: renderOrder(),
};

export { BasePropDescriptors, caption, bgColor, foreColor };
