import ControlPackages from '@controls/index';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useControl from '@hooks/useControl';
import useForm from '@hooks/useForm';
import useHoverHelpText from '@hooks/useHoverHelpText';
import { useCallback, useState } from 'react';
import { sleep } from 'src/util';
import tw, { styled } from 'twin.macro';

interface ContainerProps {
    isTransitioning: boolean;
}

const Container = styled.div(({ isTransitioning }: ContainerProps) => [
    tw`flex flex-col space-y-1 w-full text-left transition-opacity`,
    isTransitioning ? tw`opacity-0` : '',
]);

interface SelectButtonProps {
    isSelected: boolean;
}

const SelectButton = styled.button(({ isSelected }: SelectButtonProps) => [
    tw`flex flex-row space-x-2 items-center w-full`,
    isSelected ? tw`text-blue-500 font-bold` : tw`hover:text-blue-500`,
]);
const MoveButton = tw.button`text-gray-400 disabled:opacity-30 enabled:hover:text-gray-600 disabled:cursor-not-allowed hidden`;
const ControlName = styled.div(() => [
    tw`text-sm text-left flex flex-row space-x-1 text-gray-600 hover:bg-gray-100`,
    `&:hover ${MoveButton} { display: block; }`,
]);

interface Props {
    controlId: number;
    indent: number;
}

function ControlTreeNode({ controlId, indent }: Props) {
    const { control } = useControl(controlId);
    const { selectedControlId, setSelectedControlId } = useForm();
    const { getControlsByParentId, swapControlRenderOrder } = useForm();
    const [isTransitioning, setIsTransitioning] = useState(false);
    const children = getControlsByParentId(controlId)?.sort(
        (a, b) => a.properties.renderOrder - b.properties.renderOrder,
    );
    const handleClick = useCallback(() => setSelectedControlId(controlId), [setSelectedControlId, controlId]);
    const moveUpHelp = useHoverHelpText('Move this control to be rendered before any following controls.');
    const moveDownHelp = useHoverHelpText('Move this control to be rendered after any previous controls.');
    const canMove = !!control?.parentId;
    const canMoveUp = canMove && control?.properties.renderOrder > 1;
    const canMoveDown =
        control.parentId &&
        getControlsByParentId(control.parentId).reduce((prev, curr) => Math.max(prev, curr.properties.renderOrder), 0) >
            control.properties.renderOrder;

    const handleMoveUp = useCallback(async () => {
        if (!canMoveUp || !control || isTransitioning) return;
        setIsTransitioning(true);

        await sleep(50);
        swapControlRenderOrder(controlId, (control?.properties?.renderOrder || 0) - 1);
        await sleep(50);

        setIsTransitioning(false);
    }, [canMoveUp, controlId, control, isTransitioning, swapControlRenderOrder]);
    const handleMoveDown = useCallback(async () => {
        if (!canMoveDown || !control || isTransitioning) return;
        setIsTransitioning(true);

        await sleep(50);
        swapControlRenderOrder(controlId, (control?.properties?.renderOrder || 0) + 1);
        await sleep(50);

        setIsTransitioning(false);
    }, [canMoveDown, controlId, control, isTransitioning, swapControlRenderOrder]);

    if (!control) return null;
    const controlPackage = ControlPackages[control.type];

    return (
        <Container isTransitioning={isTransitioning}>
            <ControlName style={{ paddingLeft: indent * 8 }}>
                <SelectButton isSelected={controlId === selectedControlId} onClick={handleClick}>
                    <FontAwesomeIcon icon={controlPackage.icon} />
                    <span>
                        {control.name} ({controlId})
                    </span>
                </SelectButton>
                {canMove && (
                    <MoveButton disabled={!canMoveUp} onClick={handleMoveUp} {...moveUpHelp}>
                        <FontAwesomeIcon icon={solid('chevron-up')} />
                    </MoveButton>
                )}
                {canMove && (
                    <MoveButton disabled={!canMoveDown} onClick={handleMoveDown} {...moveDownHelp}>
                        <FontAwesomeIcon icon={solid('chevron-down')} />
                    </MoveButton>
                )}
            </ControlName>
            {children.map(c => (
                <ControlTreeNode key={`tree-${c.relativeId}`} controlId={c.relativeId} indent={indent + 1} />
            ))}
        </Container>
    );
}

export default ControlTreeNode;
