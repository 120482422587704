/* eslint-disable @typescript-eslint/no-empty-function */
import { FormDtoControlsInner, ProjectDto } from '@services/api';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useApiClients from './useApiClients';

interface IProjectContext {
    project: ProjectDto | undefined;
    activeFormId: string | undefined;
    newForm: ((name: string) => Promise<string>) | undefined;
    updateForm: ((formId: string, lastRelativeId: number, controls: unknown[]) => Promise<void>) | undefined;
    deleteForm: ((formId: string) => Promise<void>) | undefined;
    publish: (() => Promise<void>) | undefined;
    setActiveFormId: (formId: string | undefined) => void;
}

const ProjectContextInitial: IProjectContext = {
    project: {
        id: '',
        name: '',
        type: 'standard',
        createDate: new Date(),
        lastUpdated: new Date(),
        forms: [],
    },
    activeFormId: undefined,
    newForm: async () => '',
    updateForm: async () => {},
    deleteForm: async () => {},
    publish: async () => {},
    setActiveFormId: () => {},
};

const ProjectContext = createContext(ProjectContextInitial);

interface Props {
    projectId: string;
    children: React.ReactNode;
}

export function ProjectContextProvider({ projectId, children }: Props) {
    const { projectsClient } = useApiClients();
    const [project, setProject] = useState<ProjectDto>();
    const [activeFormId, setActiveFormId] = useState<string>();

    const reloadProject = useCallback(async () => {
        setActiveFormId(undefined);
        const projectDto = await projectsClient.getProject({ projectId });
        setProject(projectDto);
    }, [projectsClient, projectId]);

    const newForm = useCallback(
        async (name: string) => {
            if (!project) throw new Error('Project not loaded');
            const result = await projectsClient.newForm({ projectId, newFormDto: { name } });

            setProject({ ...project, forms: [...project.forms, { id: result.id, name: result.name }] });
            return result.id;
        },
        [project, projectId, projectsClient],
    );
    const updateForm = useCallback(
        async (formId: string, lastRelativeId: number, controls: unknown[]) => {
            await projectsClient.updateForm({
                projectId,
                formId,
                updateFormDto: { lastRelativeId, controls: controls as FormDtoControlsInner[] },
            });
        },
        [projectId, projectsClient],
    );
    const deleteForm = useCallback(
        async (formId: string) => {
            await projectsClient.deleteForm({
                projectId,
                formId,
            });

            if (activeFormId === formId) {
                setActiveFormId(undefined);
            }

            await reloadProject();
        },
        [projectId, activeFormId, projectsClient, reloadProject],
    );
    const publish = useCallback(async () => {
        await projectsClient.publish({ projectId });
    }, [projectsClient, projectId]);

    useEffect(() => {
        if (!projectId || !projectsClient) {
            setProject(undefined);
            setActiveFormId(undefined);
            return;
        }

        reloadProject();
    }, [projectId, reloadProject, projectsClient]);

    const value = useMemo(
        () => ({
            project,
            activeFormId,
            newForm: project ? newForm : undefined,
            updateForm: project ? updateForm : undefined,
            deleteForm: project ? deleteForm : undefined,
            publish: project ? publish : undefined,
            setActiveFormId,
        }),
        [project, activeFormId, newForm, updateForm, deleteForm, publish, setActiveFormId],
    );

    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
}

export default function useProject() {
    const context = useContext(ProjectContext);

    if (context === undefined) {
        throw new Error('useProject must be used within a ProjectContextProvider');
    }

    return context;
}
