/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormDtoControlsInner } from './FormDtoControlsInner';
import {
    FormDtoControlsInnerFromJSON,
    FormDtoControlsInnerFromJSONTyped,
    FormDtoControlsInnerToJSON,
} from './FormDtoControlsInner';

/**
 * 
 * @export
 * @interface UpdateFormDto
 */
export interface UpdateFormDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateFormDto
     */
    lastRelativeId: number;
    /**
     * 
     * @type {Array<FormDtoControlsInner>}
     * @memberof UpdateFormDto
     */
    controls: Array<FormDtoControlsInner>;
}

/**
 * Check if a given object implements the UpdateFormDto interface.
 */
export function instanceOfUpdateFormDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastRelativeId" in value;
    isInstance = isInstance && "controls" in value;

    return isInstance;
}

export function UpdateFormDtoFromJSON(json: any): UpdateFormDto {
    return UpdateFormDtoFromJSONTyped(json, false);
}

export function UpdateFormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastRelativeId': json['lastRelativeId'],
        'controls': ((json['controls'] as Array<any>).map(FormDtoControlsInnerFromJSON)),
    };
}

export function UpdateFormDtoToJSON(value?: UpdateFormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastRelativeId': value.lastRelativeId,
        'controls': ((value.controls as Array<any>).map(FormDtoControlsInnerToJSON)),
    };
}

