import { ProjectContextProvider } from '@hooks/useProject';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectViewport from '@components/ProjectViewport';

function Project() {
    const { projectId } = useParams();
    const navigate = useNavigate();

    if (!projectId) {
        navigate('/');
        return null;
    }

    return (
        <ProjectContextProvider projectId={projectId}>
            <ProjectViewport />
        </ProjectContextProvider>
    );
}

export default Project;
