/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImagelistItemPropsDto } from './ImagelistItemPropsDto';
import {
    ImagelistItemPropsDtoFromJSON,
    ImagelistItemPropsDtoFromJSONTyped,
    ImagelistItemPropsDtoToJSON,
} from './ImagelistItemPropsDto';

/**
 * 
 * @export
 * @interface ImagelistItemControlDto
 */
export interface ImagelistItemControlDto {
    /**
     * 
     * @type {ImagelistItemPropsDto}
     * @memberof ImagelistItemControlDto
     */
    properties: ImagelistItemPropsDto;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemControlDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ImagelistItemControlDto
     */
    relativeId: number;
    /**
     * 
     * @type {number}
     * @memberof ImagelistItemControlDto
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemControlDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImagelistItemControlDto
     */
    actionFDO: string;
}

/**
 * Check if a given object implements the ImagelistItemControlDto interface.
 */
export function instanceOfImagelistItemControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "relativeId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "actionFDO" in value;

    return isInstance;
}

export function ImagelistItemControlDtoFromJSON(json: any): ImagelistItemControlDto {
    return ImagelistItemControlDtoFromJSONTyped(json, false);
}

export function ImagelistItemControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagelistItemControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': ImagelistItemPropsDtoFromJSON(json['properties']),
        'type': json['type'],
        'relativeId': json['relativeId'],
        'parentId': json['parentId'],
        'name': json['name'],
        'actionFDO': json['actionFDO'],
    };
}

export function ImagelistItemControlDtoToJSON(value?: ImagelistItemControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': ImagelistItemPropsDtoToJSON(value.properties),
        'type': value.type,
        'relativeId': value.relativeId,
        'parentId': value.parentId,
        'name': value.name,
        'actionFDO': value.actionFDO,
    };
}

