import { PaneHeaderButton } from '@components/common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '@hooks/useForm';
import useHoverHelpText from '@hooks/useHoverHelpText';
import { useCallback, useState } from 'react';

function PreviewButton() {
    const { onMouseEnter, onMouseLeave } = useHoverHelpText('Streams the form directly to your signed-in client.');
    const { preview } = useForm();
    const [previewState, setPreviewState] = useState<'idle' | 'busy' | 'success' | 'error'>('idle');

    const handlePreview = useCallback(async () => {
        if (previewState !== 'idle') return;
        try {
            setPreviewState('busy');
            await preview();
            setPreviewState('success');
        } catch {
            setPreviewState('idle');
        } finally {
            setTimeout(() => setPreviewState('idle'), 1000);
        }
    }, [previewState, preview]);

    const getPreviewCaption = () => {
        switch (previewState) {
            case 'busy':
                return 'Sending to Client';
            case 'success':
                return 'Preview Sent!';
            case 'error':
                return 'Preview Failed';
            case 'idle':
            default:
                return 'Preview';
        }
    };

    return (
        <PaneHeaderButton type="button" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handlePreview}>
            {previewState === 'idle' && <FontAwesomeIcon size="lg" icon={solid('magnifying-glass')} />}
            {previewState === 'busy' && <FontAwesomeIcon size="lg" spin icon={solid('spinner')} />}
            {previewState === 'success' && <FontAwesomeIcon size="lg" icon={solid('check')} />}
            {previewState === 'error' && <FontAwesomeIcon size="lg" icon={solid('triangle-exclamation')} />}
            <span>{getPreviewCaption()}</span>
        </PaneHeaderButton>
    );
}

export default PreviewButton;
