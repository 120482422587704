import tw, { styled } from 'twin.macro';
import Header from '@components/Header';
import useLogin from '@hooks/useLogin';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full`;
const Viewport = styled.div(() => [
    tw`flex flex-grow flex-row items-center justify-center`,
    'max-height: calc(100% - 100px);',
]);
const ModalContainer = tw.div`bg-white shadow rounded border border-gray-200 p-4 flex flex-row space-x-4`;
const Section = tw.div`flex flex-col space-y-4 bg-gray-100 w-96 p-4 shadow-lg`;
const SectionTitle = tw.span`text-lg font-bold`;
const Button = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 flex flex-col items-start text-left space-y-4 enabled:hover:(border-blue-400 text-black) enabled:active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50) disabled:(bg-gray-300 cursor-not-allowed)`;
const Title = tw.h2`text-base font-bold`;
const Description = tw.span`leading-relaxed text-xs`;
const SpinnerContainer = tw.div`w-full h-full absolute top-0 left-0 flex items-center justify-center bg-black bg-opacity-80`;
const Spinner = styled(FontAwesomeIcon)(() => [tw`animate-spin text-blue-400`]);
const ErrorContainer = tw.div`bg-red-100 border border-red-200 p-2 flex items-center justify-center text-xs`;

const getStatePath = (original: string | undefined) => {
    if (!original || original === '/logoff') return '/';
    return original;
};

function Login() {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    const { getLoginUrl, isBusy } = useLogin();
    const fromPath = (location?.state as any)?.from?.pathname;
    const from = getStatePath(fromPath);

    const handleLogin = useCallback(async () => {
        setHasError(false);
        const loginUrl = await getLoginUrl(from);

        if (loginUrl) {
            window.location.assign(loginUrl);
        } else {
            setHasError(true);
        }
    }, [getLoginUrl, from]);

    return (
        <Container>
            <Header />
            <Viewport>
                <ModalContainer>
                    <Section>
                        <SectionTitle>Welcome to ReVPD</SectionTitle>
                        {hasError && <ErrorContainer>There was a problem signing in. Please try again.</ErrorContainer>}
                        <Button type="button" onClick={handleLogin}>
                            <Title>Sign In with P3OL</Title>
                            <Description>
                                Use your existing P3OL Screen Name to sign in to save your work, track changes, and push
                                to your client instantly.
                            </Description>
                        </Button>
                        <Button type="button" disabled>
                            <Title>Continue as Guest</Title>
                            <Description>
                                Play around with the editor to get a feel for how it works. As a guest, you cannot save
                                your work, push to clients, upload custom assets, or publish.
                            </Description>
                        </Button>
                    </Section>
                    <Section>
                        <SectionTitle>What is this?</SectionTitle>
                        <Description>
                            ReVPD is a visual drag-and-drop editor for creating forms. Upload custom images, test in the
                            real client, and iterate rapidly. When your content is ready to go, publish it for the
                            world!
                        </Description>
                    </Section>
                </ModalContainer>
                {isBusy && (
                    <SpinnerContainer>
                        <Spinner icon={solid('spinner')} />
                    </SpinnerContainer>
                )}
            </Viewport>
        </Container>
    );
}

export default Login;
