import { ReactElement } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ControlPropsDescriptor } from './PropDescriptors';

export type ControlType =
    | 'window'
    | 'button'
    | 'label'
    | 'checkbox-list'
    | 'checkbox'
    | 'radio-list'
    | 'radio'
    | 'picture'
    | 'imagelist'
    | 'imagelist-item'
    | 'combo-box'
    | 'textbox';
// | 'group'
// | 'number-input'
// | 'progress-bar'
// | 'radio-list'
// | 'radio'
// | 'text-input'
// | 'text-view';

export interface BaseControl<TProps extends BaseProps> {
    relativeId: number;
    parentId?: number | undefined;
    name: string;
    type: ControlType;
    properties: TProps;
}

export interface ActionControl {
    actionFDO: string;
}

export interface PrecisePositionProps {
    placement: 'precise';
    width: number;
    height: number;
    left: number;
    top: number;
}

export interface RelativePositionProps {
    placement: 'relative';
    orientation: 'h' | 'v';
    hAlign: 'c' | 'l' | 'r' | 'f' | 'e' | 'n';
    vAlign: 'c' | 't' | 'b' | 'f' | 'e' | 'n';
}

export interface InheritedPositionProps {
    placement: 'inherited';
}

export type PositionProps = PrecisePositionProps | RelativePositionProps | InheritedPositionProps;

export interface BaseProps {
    position: PositionProps;
    isEnabled: boolean;
    frameStyle: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
    renderOrder: number;
}

type RenderDesignerFunc = (
    controlId: number,
    children?: React.ReactElement | React.ReactElement[] | undefined,
) => ReactElement;

export interface ControlPackage<TProps extends BaseProps, TControl extends BaseControl<TProps>> {
    type: ControlType;
    icon: IconDefinition;
    canHaveChildren: boolean;
    canHaveAction: boolean;
    validParentTypes?: undefined | ControlType[];
    factory: (parentId: number | undefined, renderOrder: number) => TControl;
    propDescriptors: ControlPropsDescriptor<TProps>;
    renderDesigner: RenderDesignerFunc;
    getPreviewRenderSize?: ((props: TProps) => [number | undefined, number | undefined]) | undefined;
    upgradePropsVersion: (props: TProps) => TProps;
}

export function ensureSet<TObj, TProp extends keyof TObj, TValue extends TObj[TProp]>(
    source: TObj,
    name: TProp,
    value: TValue,
) {
    if (!source[name]) {
        return { ...source, [name]: value };
    }

    return source;
}
