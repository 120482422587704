/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArtListDto,
  ArtListItemDto,
  ArtUploadRequestDto,
  ArtUploadResponseDto,
} from '../models';
import {
    ArtListDtoFromJSON,
    ArtListDtoToJSON,
    ArtListItemDtoFromJSON,
    ArtListItemDtoToJSON,
    ArtUploadRequestDtoFromJSON,
    ArtUploadRequestDtoToJSON,
    ArtUploadResponseDtoFromJSON,
    ArtUploadResponseDtoToJSON,
} from '../models';

export interface GetAssetRequest {
    id: string;
}

export interface GetOwnedArtRequest {
    token?: number;
}

export interface GetSystemArtRequest {
    token?: number;
}

export interface PostNewAssetRequest {
    artUploadRequestDto: ArtUploadRequestDto;
}

/**
 * 
 */
export class ArtApi extends runtime.BaseAPI {

    /**
     */
    async getAssetRaw(requestParameters: GetAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtListItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/art/asset/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtListItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAsset(requestParameters: GetAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtListItemDto> {
        const response = await this.getAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOwnedArtRaw(requestParameters: GetOwnedArtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtListDto>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/art/owned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtListDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOwnedArt(requestParameters: GetOwnedArtRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtListDto> {
        const response = await this.getOwnedArtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSystemArtRaw(requestParameters: GetSystemArtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtListDto>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/art/system`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtListDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSystemArt(requestParameters: GetSystemArtRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtListDto> {
        const response = await this.getSystemArtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postNewAssetRaw(requestParameters: PostNewAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtUploadResponseDto>> {
        if (requestParameters.artUploadRequestDto === null || requestParameters.artUploadRequestDto === undefined) {
            throw new runtime.RequiredError('artUploadRequestDto','Required parameter requestParameters.artUploadRequestDto was null or undefined when calling postNewAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/art`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ArtUploadRequestDtoToJSON(requestParameters.artUploadRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtUploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async postNewAsset(requestParameters: PostNewAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtUploadResponseDto> {
        const response = await this.postNewAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
