/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrecisePositionPropsDto
 */
export interface PrecisePositionPropsDto {
    /**
     * 
     * @type {string}
     * @memberof PrecisePositionPropsDto
     */
    placement: string;
    /**
     * 
     * @type {number}
     * @memberof PrecisePositionPropsDto
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof PrecisePositionPropsDto
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof PrecisePositionPropsDto
     */
    left: number;
    /**
     * 
     * @type {number}
     * @memberof PrecisePositionPropsDto
     */
    top: number;
}

/**
 * Check if a given object implements the PrecisePositionPropsDto interface.
 */
export function instanceOfPrecisePositionPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placement" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "left" in value;
    isInstance = isInstance && "top" in value;

    return isInstance;
}

export function PrecisePositionPropsDtoFromJSON(json: any): PrecisePositionPropsDto {
    return PrecisePositionPropsDtoFromJSONTyped(json, false);
}

export function PrecisePositionPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrecisePositionPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placement': json['placement'],
        'width': json['width'],
        'height': json['height'],
        'left': json['left'],
        'top': json['top'],
    };
}

export function PrecisePositionPropsDtoToJSON(value?: PrecisePositionPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placement': value.placement,
        'width': value.width,
        'height': value.height,
        'left': value.left,
        'top': value.top,
    };
}

