/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ButtonControlDto,
    instanceOfButtonControlDto,
    ButtonControlDtoFromJSON,
    ButtonControlDtoFromJSONTyped,
    ButtonControlDtoToJSON,
} from './ButtonControlDto';
import {
    CheckboxControlDto,
    instanceOfCheckboxControlDto,
    CheckboxControlDtoFromJSON,
    CheckboxControlDtoFromJSONTyped,
    CheckboxControlDtoToJSON,
} from './CheckboxControlDto';
import {
    CheckboxListControlDto,
    instanceOfCheckboxListControlDto,
    CheckboxListControlDtoFromJSON,
    CheckboxListControlDtoFromJSONTyped,
    CheckboxListControlDtoToJSON,
} from './CheckboxListControlDto';
import {
    ComboBoxControlDto,
    instanceOfComboBoxControlDto,
    ComboBoxControlDtoFromJSON,
    ComboBoxControlDtoFromJSONTyped,
    ComboBoxControlDtoToJSON,
} from './ComboBoxControlDto';
import {
    ImagelistControlDto,
    instanceOfImagelistControlDto,
    ImagelistControlDtoFromJSON,
    ImagelistControlDtoFromJSONTyped,
    ImagelistControlDtoToJSON,
} from './ImagelistControlDto';
import {
    ImagelistItemControlDto,
    instanceOfImagelistItemControlDto,
    ImagelistItemControlDtoFromJSON,
    ImagelistItemControlDtoFromJSONTyped,
    ImagelistItemControlDtoToJSON,
} from './ImagelistItemControlDto';
import {
    LabelControlDto,
    instanceOfLabelControlDto,
    LabelControlDtoFromJSON,
    LabelControlDtoFromJSONTyped,
    LabelControlDtoToJSON,
} from './LabelControlDto';
import {
    PictureControlDto,
    instanceOfPictureControlDto,
    PictureControlDtoFromJSON,
    PictureControlDtoFromJSONTyped,
    PictureControlDtoToJSON,
} from './PictureControlDto';
import {
    RadioControlDto,
    instanceOfRadioControlDto,
    RadioControlDtoFromJSON,
    RadioControlDtoFromJSONTyped,
    RadioControlDtoToJSON,
} from './RadioControlDto';
import {
    RadioListControlDto,
    instanceOfRadioListControlDto,
    RadioListControlDtoFromJSON,
    RadioListControlDtoFromJSONTyped,
    RadioListControlDtoToJSON,
} from './RadioListControlDto';
import {
    TextBoxControlDto,
    instanceOfTextBoxControlDto,
    TextBoxControlDtoFromJSON,
    TextBoxControlDtoFromJSONTyped,
    TextBoxControlDtoToJSON,
} from './TextBoxControlDto';
import {
    WindowControlDto,
    instanceOfWindowControlDto,
    WindowControlDtoFromJSON,
    WindowControlDtoFromJSONTyped,
    WindowControlDtoToJSON,
} from './WindowControlDto';

/**
 * @type FormDtoControlsInner
 * 
 * @export
 */
export type FormDtoControlsInner = { type: 'button' } & ButtonControlDto | { type: 'checkbox' } & CheckboxControlDto | { type: 'checkbox-list' } & CheckboxListControlDto | { type: 'combo-box' } & ComboBoxControlDto | { type: 'imagelist' } & ImagelistControlDto | { type: 'imagelist-item' } & ImagelistItemControlDto | { type: 'label' } & LabelControlDto | { type: 'picture' } & PictureControlDto | { type: 'radio' } & RadioControlDto | { type: 'radio-list' } & RadioListControlDto | { type: 'textbox' } & TextBoxControlDto | { type: 'window' } & WindowControlDto;

export function FormDtoControlsInnerFromJSON(json: any): FormDtoControlsInner {
    return FormDtoControlsInnerFromJSONTyped(json, false);
}

export function FormDtoControlsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDtoControlsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'button':
            return {...ButtonControlDtoFromJSONTyped(json, true), type: 'button'};
        case 'checkbox':
            return {...CheckboxControlDtoFromJSONTyped(json, true), type: 'checkbox'};
        case 'checkbox-list':
            return {...CheckboxListControlDtoFromJSONTyped(json, true), type: 'checkbox-list'};
        case 'combo-box':
            return {...ComboBoxControlDtoFromJSONTyped(json, true), type: 'combo-box'};
        case 'imagelist':
            return {...ImagelistControlDtoFromJSONTyped(json, true), type: 'imagelist'};
        case 'imagelist-item':
            return {...ImagelistItemControlDtoFromJSONTyped(json, true), type: 'imagelist-item'};
        case 'label':
            return {...LabelControlDtoFromJSONTyped(json, true), type: 'label'};
        case 'picture':
            return {...PictureControlDtoFromJSONTyped(json, true), type: 'picture'};
        case 'radio':
            return {...RadioControlDtoFromJSONTyped(json, true), type: 'radio'};
        case 'radio-list':
            return {...RadioListControlDtoFromJSONTyped(json, true), type: 'radio-list'};
        case 'textbox':
            return {...TextBoxControlDtoFromJSONTyped(json, true), type: 'textbox'};
        case 'window':
            return {...WindowControlDtoFromJSONTyped(json, true), type: 'window'};
        default:
            throw new Error(`No variant of FormDtoControlsInner exists with 'type=${json['type']}'`);
    }
}

export function FormDtoControlsInnerToJSON(value?: FormDtoControlsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'button':
            return ButtonControlDtoToJSON(value);
        case 'checkbox':
            return CheckboxControlDtoToJSON(value);
        case 'checkbox-list':
            return CheckboxListControlDtoToJSON(value);
        case 'combo-box':
            return ComboBoxControlDtoToJSON(value);
        case 'imagelist':
            return ImagelistControlDtoToJSON(value);
        case 'imagelist-item':
            return ImagelistItemControlDtoToJSON(value);
        case 'label':
            return LabelControlDtoToJSON(value);
        case 'picture':
            return PictureControlDtoToJSON(value);
        case 'radio':
            return RadioControlDtoToJSON(value);
        case 'radio-list':
            return RadioListControlDtoToJSON(value);
        case 'textbox':
            return TextBoxControlDtoToJSON(value);
        case 'window':
            return WindowControlDtoToJSON(value);
        default:
            throw new Error(`No variant of FormDtoControlsInner exists with 'type=${value['type']}'`);
    }

}

