/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectFormDto } from './ProjectFormDto';
import {
    ProjectFormDtoFromJSON,
    ProjectFormDtoFromJSONTyped,
    ProjectFormDtoToJSON,
} from './ProjectFormDto';
import type { ProjectType } from './ProjectType';
import {
    ProjectTypeFromJSON,
    ProjectTypeFromJSONTyped,
    ProjectTypeToJSON,
} from './ProjectType';

/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    name: string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDto
     */
    type: ProjectType;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    createDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDto
     */
    lastUpdated: Date;
    /**
     * 
     * @type {Array<ProjectFormDto>}
     * @memberof ProjectDto
     */
    forms: Array<ProjectFormDto>;
}

/**
 * Check if a given object implements the ProjectDto interface.
 */
export function instanceOfProjectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createDate" in value;
    isInstance = isInstance && "lastUpdated" in value;
    isInstance = isInstance && "forms" in value;

    return isInstance;
}

export function ProjectDtoFromJSON(json: any): ProjectDto {
    return ProjectDtoFromJSONTyped(json, false);
}

export function ProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': ProjectTypeFromJSON(json['type']),
        'createDate': (new Date(json['createDate'])),
        'lastUpdated': (new Date(json['lastUpdated'])),
        'forms': ((json['forms'] as Array<any>).map(ProjectFormDtoFromJSON)),
    };
}

export function ProjectDtoToJSON(value?: ProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': ProjectTypeToJSON(value.type),
        'createDate': (value.createDate.toISOString()),
        'lastUpdated': (value.lastUpdated.toISOString()),
        'forms': ((value.forms as Array<any>).map(ProjectFormDtoToJSON)),
    };
}

