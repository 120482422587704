import { useGlobal } from 'reactn';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import useLogin from '@hooks/useLogin';

interface Props {
    children: JSX.Element;
}

function RequireAuth({ children }: Props) {
    const isRefreshingToken = useRef(false);
    const [auth, setAuth] = useGlobal('auth');
    const [isGuest] = useGlobal('isGuest');
    const location = useLocation();
    const { performTokenRefresh } = useLogin();
    const { accessToken, refreshAt, refreshToken } = auth;

    useEffect(() => {
        if (isRefreshingToken.current) {
            return undefined;
        }

        const timer = setInterval(async () => {
            if (new Date() >= new Date(refreshAt) && !isRefreshingToken.current) {
                isRefreshingToken.current = true;
                try {
                    const result = await performTokenRefresh(accessToken, refreshToken);

                    if (!result) throw new Error('Failed to refresh token');

                    const newExpiresAt = new Date();
                    newExpiresAt.setTime(newExpiresAt.getTime() + result.expiresIn * 1000);

                    const newRefreshAt = new Date();
                    newRefreshAt.setTime(newRefreshAt.getTime() + (result.expiresIn - 300) * 1000);

                    await setAuth({
                        accessToken: result.accessToken,
                        refreshToken: result.refreshToken || refreshToken,
                        expiresAt: newExpiresAt,
                        refreshAt: newRefreshAt,
                    });
                } catch {
                    setAuth({
                        accessToken: '',
                        refreshToken: '',
                        expiresAt: new Date(),
                        refreshAt: new Date(),
                    });
                } finally {
                    isRefreshingToken.current = false;
                }
            }
        }, 30000);

        return () => clearInterval(timer);
    }, [refreshAt, accessToken, refreshToken, performTokenRefresh, setAuth]);

    if (!accessToken && !isGuest) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth;
