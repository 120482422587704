import { BaseControl, BaseProps } from '@controls/Base';
import useControl from '@hooks/useControl';
import tw from 'twin.macro';
import RadioControl from './Control';

const Label = tw.label`flex flex-row space-x-1 items-center`;

interface Props {
    controlId: number;
}

function isValidType(control: BaseControl<BaseProps> | undefined): control is RadioControl {
    return !!control && control.type === 'radio';
}

function Radio({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isValidType(control)) throw new Error('Bad render call');

    let [width, height] = [150, 50];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.height;
    }

    return (
        <Label
            htmlFor={`chk-${controlId}`}
            style={{
                width,
                height,
            }}
        >
            <input type="radio" value={controlId} />
            <span>{control.properties.caption}</span>
        </Label>
    );
}

export default Radio;
