export interface PropGroup {
    title: string;
    sort: number;
}

const AppearancePropGroup: PropGroup = {
    title: 'Appearance',
    sort: 1,
};

const BehaviorPropGroup: PropGroup = {
    title: 'Behavior',
    sort: 2,
};

const PositionPropGroup: PropGroup = {
    title: 'Position',
    sort: 0,
};

const MiscellaneousPropGroup: PropGroup = {
    title: 'Miscellaneous',
    sort: 3,
};

export { AppearancePropGroup, BehaviorPropGroup, PositionPropGroup, MiscellaneousPropGroup };
