/* tslint:disable */
/* eslint-disable */
/**
 * ReVPD API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ButtonPropsDtoPosition } from './ButtonPropsDtoPosition';
import {
    ButtonPropsDtoPositionFromJSON,
    ButtonPropsDtoPositionFromJSONTyped,
    ButtonPropsDtoPositionToJSON,
} from './ButtonPropsDtoPosition';

/**
 * 
 * @export
 * @interface WindowPropsDto
 */
export interface WindowPropsDto {
    /**
     * 
     * @type {boolean}
     * @memberof WindowPropsDto
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    frameStyle: string;
    /**
     * 
     * @type {number}
     * @memberof WindowPropsDto
     */
    renderOrder: number;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    caption: string;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    bgColor: string;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    bgArt: string;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    windowPosition: string;
    /**
     * 
     * @type {boolean}
     * @memberof WindowPropsDto
     */
    modal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WindowPropsDto
     */
    resizableV: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WindowPropsDto
     */
    resizableH: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WindowPropsDto
     */
    autoCloseOnWindowLimit: boolean;
    /**
     * 
     * @type {string}
     * @memberof WindowPropsDto
     */
    referenceImage: string;
    /**
     * 
     * @type {ButtonPropsDtoPosition}
     * @memberof WindowPropsDto
     */
    position: ButtonPropsDtoPosition;
}

/**
 * Check if a given object implements the WindowPropsDto interface.
 */
export function instanceOfWindowPropsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "frameStyle" in value;
    isInstance = isInstance && "renderOrder" in value;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "bgColor" in value;
    isInstance = isInstance && "bgArt" in value;
    isInstance = isInstance && "windowPosition" in value;
    isInstance = isInstance && "modal" in value;
    isInstance = isInstance && "resizableV" in value;
    isInstance = isInstance && "resizableH" in value;
    isInstance = isInstance && "autoCloseOnWindowLimit" in value;
    isInstance = isInstance && "referenceImage" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function WindowPropsDtoFromJSON(json: any): WindowPropsDto {
    return WindowPropsDtoFromJSONTyped(json, false);
}

export function WindowPropsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindowPropsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnabled': json['isEnabled'],
        'frameStyle': json['frameStyle'],
        'renderOrder': json['renderOrder'],
        'caption': json['caption'],
        'bgColor': json['bgColor'],
        'bgArt': json['bgArt'],
        'windowPosition': json['windowPosition'],
        'modal': json['modal'],
        'resizableV': json['resizableV'],
        'resizableH': json['resizableH'],
        'autoCloseOnWindowLimit': json['autoCloseOnWindowLimit'],
        'referenceImage': json['referenceImage'],
        'position': ButtonPropsDtoPositionFromJSON(json['position']),
    };
}

export function WindowPropsDtoToJSON(value?: WindowPropsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnabled': value.isEnabled,
        'frameStyle': value.frameStyle,
        'renderOrder': value.renderOrder,
        'caption': value.caption,
        'bgColor': value.bgColor,
        'bgArt': value.bgArt,
        'windowPosition': value.windowPosition,
        'modal': value.modal,
        'resizableV': value.resizableV,
        'resizableH': value.resizableH,
        'autoCloseOnWindowLimit': value.autoCloseOnWindowLimit,
        'referenceImage': value.referenceImage,
        'position': ButtonPropsDtoPositionToJSON(value.position),
    };
}

