import { MouseEvent as ReactMouseEvent, useCallback, useState, useRef, useMemo, useEffect } from 'react';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useControl from '@hooks/useControl';
import ControlPackages from '@controls/index';
import styled from 'styled-components';
import ActionFDOEditor from '@components/ActionFDOEditor';
import PopupMenu from '@components/PopupMenu';
import { BaseControl, BaseProps, ControlPackage } from '@controls/Base';
import PopupMenuItem from '@components/PopupMenuItem';
import useForm from '@hooks/useForm';
import useProject from '@hooks/useProject';
import { ProjectType } from '@services/api';

const Container = tw.div`absolute p-1 flex flex-row justify-between z-10 bg-gray-600 bg-opacity-90 shadow min-w-[96px]`;
const Command = tw.button`text-gray-300 hover:opacity-100`;
const LeftActions = tw.div`flex flex-row`;
const AddChildCommand = styled(Command)(() => [tw`hover:text-green-300`]);
const ActionCommand = styled(Command)(() => [tw`hover:text-blue-300`]);
const DeleteCommand = styled(Command)(() => [tw`hover:text-red-300`]);

interface Position {
    top: number;
    left: number;
    width: number;
}

interface Props {
    controlId: number;
}

function DecoratorMenu({ controlId }: Props) {
    const menuPos = useRef<[number, number]>([0, 0]);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { control } = useControl(controlId);
    const { project } = useProject();
    const { addControl, removeControl, getControlsByParentId, setSelectedControlId } = useForm();
    const [position, setPosition] = useState<Position>();
    const controlType = control?.type;
    const controlPackage = controlType ? ControlPackages[controlType] : undefined;
    const eligibleChildren = useMemo(
        () =>
            controlType
                ? Object.values(ControlPackages)
                      .filter(p => p.validParentTypes?.includes(controlType))
                      .sort((a, b) => a.type.localeCompare(b.type))
                : [],
        [controlType],
    );

    const handleAddControl = useCallback(
        async (targetPackage: ControlPackage<BaseProps, BaseControl<BaseProps>>) => {
            setIsPopupOpen(false);
            const allChildren = getControlsByParentId(controlId);
            const renderOrder = allChildren.reduce((prev, curr) => Math.max(prev, curr.properties.renderOrder), 0) + 1;
            const newId = addControl(targetPackage.factory(controlId, renderOrder));
            setSelectedControlId(newId);
        },
        [addControl, getControlsByParentId, setSelectedControlId, controlId],
    );
    const handleShowAddMenu = useCallback(
        (evt: ReactMouseEvent) => {
            if (eligibleChildren.length === 1) {
                handleAddControl(eligibleChildren[0]);
            } else {
                menuPos.current = [evt.clientX, evt.clientY];
                setIsPopupOpen(true);
            }
        },
        [handleAddControl, eligibleChildren],
    );
    const handleCloseAddMenu = useCallback(() => setIsPopupOpen(false), []);
    const handleRemove = useCallback(() => {
        removeControl(controlId);
    }, [removeControl, controlId]);

    useEffect(() => {
        const rootElement = document.getElementById(`decorator-root`);
        const targetElement = document.getElementById(`decorator-attachment-target-${controlId}`);
        const handleMove = () => {
            if (!targetElement || !rootElement) return;

            const needPosition = !position;
            const rootBox = rootElement.getBoundingClientRect();
            const targetBox = targetElement.getBoundingClientRect();

            const left = targetBox.left - rootBox.left;
            const top = targetBox.top - rootBox.top - 35;
            const { width } = targetBox;

            const leftChange = !needPosition && left !== position.left;
            const topChange = !needPosition && top !== position.top;
            const widthChange = !needPosition && width !== position.width;

            if (needPosition || leftChange || topChange || widthChange) {
                setPosition({
                    top,
                    left,
                    width,
                });
            }
        };

        document.addEventListener('mouseup', handleMove);
        document.addEventListener('mousemove', handleMove);
        handleMove();

        return () => {
            document.removeEventListener('mouseup', handleMove);
            document.removeEventListener('mousemove', handleMove);
        };
    }, [controlId, position]);

    if (!control || !controlPackage) return null;

    return (
        <Container style={{ top: position?.top, width: position?.width, left: position?.left }}>
            <LeftActions>
                {controlPackage.canHaveChildren && eligibleChildren.length > 0 && (
                    <>
                        <AddChildCommand onClick={handleShowAddMenu} type="button">
                            <FontAwesomeIcon icon={solid('circle-plus')} />
                        </AddChildCommand>
                        <PopupMenu
                            isOpen={isPopupOpen}
                            left={menuPos.current[0]}
                            top={menuPos.current[1]}
                            onClose={handleCloseAddMenu}
                            header="Add Control"
                        >
                            {eligibleChildren.map(ctype => (
                                <PopupMenuItem
                                    key={ctype.type}
                                    text={ctype.type.replace('-', ' ')}
                                    icon={<FontAwesomeIcon icon={ctype.icon} />}
                                    onSelect={() => handleAddControl(ctype)}
                                />
                            ))}
                        </PopupMenu>
                    </>
                )}
                {controlPackage.canHaveAction && project?.type === ProjectType.Standard && (
                    <>
                        <ActionCommand
                            onClick={() => setIsEditorOpen(true)}
                            type="button"
                            title="Set FDO to execute when triggered"
                        >
                            <FontAwesomeIcon icon={solid('code')} />
                        </ActionCommand>
                        <ActionFDOEditor
                            isOpen={isEditorOpen}
                            onClose={() => setIsEditorOpen(false)}
                            controlId={controlId}
                        />
                    </>
                )}
            </LeftActions>
            {control.type !== 'window' && (
                <DeleteCommand type="button" onClick={handleRemove}>
                    <FontAwesomeIcon icon={solid('trash')} />
                </DeleteCommand>
            )}
        </Container>
    );
}

export default DecoratorMenu;
