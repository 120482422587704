import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import Checkbox from './Checkbox';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';

const createCheckbox = (parentId: number | undefined, renderOrder: number): Control => ({
    relativeId: -1,
    parentId,
    name: 'NewCheckbox',
    type: 'checkbox',
    actionFDO: '',
    properties: {
        caption: 'I am a checkbox',
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 96,
            height: 32,
        },
        isEnabled: true,
        frameStyle: '0',
        renderOrder,
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const checkboxPackage: ControlPackage<Props, Control> = {
    type: 'checkbox',
    icon: solid('square-check'),
    canHaveChildren: false,
    canHaveAction: true,
    validParentTypes: ['checkbox-list'],
    factory: createCheckbox,
    propDescriptors: PropsDescriptor,
    renderDesigner: controlId => createElement(Checkbox, { controlId }),
    upgradePropsVersion,
};

export default checkboxPackage;
