import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHoverHelpText from '@hooks/useHoverHelpText';
import useProject from '@hooks/useProject';
import { ProjectFormDto } from '@services/api';
import { useCallback } from 'react';
import tw, { styled } from 'twin.macro';

const Container = tw.div`flex flex-col space-y-1 w-full text-left`;

interface FormNameProps {
    isSelected: boolean;
}

const SelectButton = styled.button(({ isSelected }: FormNameProps) => [
    tw`flex flex-row space-x-2 items-center w-full`,
    isSelected ? tw`text-blue-500 font-bold` : tw`hover:text-blue-500`,
]);

const DeleteButton = tw.button`text-gray-400 disabled:opacity-30 enabled:hover:text-blue-600 disabled:cursor-not-allowed hidden`;

const FormName = styled.div(() => [
    tw`text-sm text-left flex flex-row space-x-1 text-gray-600 hover:bg-gray-100`,
    `&:hover ${DeleteButton} { display: block; }`,
]);

interface Props {
    form: ProjectFormDto;
}

function ProjectExplorerTreeNode({ form }: Props) {
    const { activeFormId, setActiveFormId, deleteForm } = useProject();
    const { id, name } = form;
    const deleteHelp = useHoverHelpText('Remove this window from the project.');
    const handleClick = useCallback(() => setActiveFormId(id), [setActiveFormId, id]);
    const handleDelete = useCallback(async () => {
        if (!deleteForm) return;

        // eslint-disable-next-line no-alert
        if (!window.confirm("Are you sure you want to delete this form? It'll be gone forever!")) {
            return;
        }

        await deleteForm(id);
    }, [deleteForm, id]);

    return (
        <Container>
            <FormName>
                <SelectButton onClick={handleClick} isSelected={id === activeFormId}>
                    <FontAwesomeIcon icon={solid('window-maximize')} />
                    <span>{name}</span>
                </SelectButton>
                <DeleteButton onClick={handleDelete} {...deleteHelp}>
                    <FontAwesomeIcon icon={solid('trash')} />
                </DeleteButton>
            </FormName>
        </Container>
    );
}

export default ProjectExplorerTreeNode;
